html { box-sizing: border-box; 
}
*, *:before, *:after { box-sizing: inherit; }
img { max-width: 100%; vertical-align: middle; border: 0; }
nav ul, nav ol, ol, .banner-container ul { list-style-type: none; padding: 0; margin: 0; }

body {
	margin: 0;
	font-family: myriad-pro, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	background: #000;
}
p {
	margin: 0;
}
a {
	color: #CC9D70;
	text-decoration: none;
}
a:link,
a:visited {
	color: #CC9D70;
	text-decoration: none;
}
a:hover, 
a:focus {
	color: #FFD98C;
	text-decoration: none;
}
a:active {
	color: #FFD98C;
	text-decoration: none;
}

.registration-text a {
	color: #CC9D70;
	text-decoration: none;
}
.registration-text a:link,
.registration-text a:visited {
	color: #CC9D70;
	text-decoration: none;
}
.registration-text a:hover, 
.registration-text a:focus {
	color: #FFD98C;
	text-decoration: none;
}
.registration-text a:active {
	color: #FFD98C;
	text-decoration: none;
}




.nav-outer-wrapper {
		background-color: #000;
		padding: 0 20px;
	}
.navbar {
	margin: auto;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
}
nav li a,
nav li .nav-signup {
		display: block;
		font-weight: 600;
		letter-spacing: 0.03em;
		color: #fff;
		text-decoration: none;		/* removed - seems no reason- padding: 10px 0;*/
}
nav li a:link,
nav li a:visited,
nav li .nav-signup {
		color: #fff;
		text-decoration: none;
}
nav li a:hover, 
nav li a:focus,
nav li .nav-signup:hover,
nav li .nav-signup:focus {
	color: #CC9D70;
}
nav li .nav-signup {
		cursor: pointer;
}
#nav-checkbox {
	position: absolute;
	top: 12px;
	right: 20px;
	opacity: 0;
}
.just-free-pps {
	display: none;
}

.side-nav-buttons {
	grid-row-start: 3;
	grid-row-end: 4;
}

/*-------------------------------------------------Footer Styles*/
.contact-word {
	display: none;
}
.footer-outer-wrapper {
	padding: 0 20px;
	}
.footer {
	padding: 0 20px;
}
.footer-container {
	margin: auto;
	max-width: 1110px
}
.footer-container h3 {
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	margin: .75em 0 1.5em;
	padding-top: 1em;
}
.footer-container p {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5em;
	color: rgba(255,255,255, .7);
	margin-top: 1em;
}
.copyright {
	margin: 1.25em 0;
}
.footer-container a {
	color: rgba(255,255,255, .7);
	text-decoration: none;
}
.footer-container a:link,
.footer-container a:visited {
	color: rgba(255,255,255, .7);
	text-decoration: none;
}
.footer-container a:hover, 
.footer-container a:focus {
	color: #fff;
	text-decoration: none;
}
.footer-container a:active {
	color: rgba(255,255,255, .7);
	text-decoration: none;
}
.divider-container {
	margin: .5em auto 0;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
}
.footer-image-container {	
}
.horse-w-stars {		
	width: 166px;
}
.rule-left {		
	width: 100%;
	padding-right: 2em;
	padding-top: 1.75em;
}
.rule-right {		
	width: 100%;
	padding-left: 2em;
	padding-top: 1.75em;
}
.rule-left hr,
.rule-right hr {		
	size: 1px;
	color: rgba(255,255,255, .7);
	width: 100%;
}
.footer-grid {
	display: grid;
	padding: 0;
	gap: 0 30px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

/*-------------------------------------------------MAIN HOMEPAGE STYLES----*/

.banner-outer-wrapper {
	background-color: #000;
	padding: 0 20px;
}
.banner-wrapper {
	margin: 0 auto 30px;
	max-width: 1110px;
	padding: 0;
	display: flex;
	justify-content: space-between; 
	align-items: center; 
}
.sok-animation {
	width: 100%;
	height: 67px;
	display: flex;
	margin: 0 0 0 0;
	padding: .5em .5em .7em;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 1.5em;
	color: #fff;
	border-radius: 4px;
	border: 1px solid #CB0906;
	cursor: pointer;
}
.drf-banner {
	margin: 0 0 0 .9375rem;
}
.drf-banner img {
	width: 540px;
	max-width: 540px;
}
.mobile-pps {
	display: none;
}
.t-shirt-images {
	text-align: center;
	margin-bottom: 20px;
}
.banner-container {
	margin: 0 auto 1em;
}
.banner-container ul {
	display: flex;
	justify-content: space-between;
}
.banner-container li {
	padding-right: .5em;
}
.banner-container li:last-of-type {
	padding-right: 0; 
}
.homepage-banner-standard {
	width: 728px;
}
.homepage-banner1 {
	width: 360px;
}
.homepage-banner2 {
	width: 360px;
}
.homepage-banner-nhc {
	width: 360px;
}
.main-homepage .leaderboards {
	max-width: 825px;
	margin: 0 auto 20px;
	padding: 0;
	display: grid;
	gap: 15px;
	grid-template-columns: 1fr 1fr 1fr;
}
.main-homepage .leaderboard-column {
	width: 100%;
	background-image: linear-gradient(230deg, #0c171c 41% 57%, #1b95b2 180%);		
	box-shadow: 2px 2px 2px #CC9D70;
	border-radius: 8px;
	border-top: 1px solid #CC9D70;
}
.main-homepage .leaderboards a {
	width: 100%;
}
.main-homepage .player-row {
	margin: 0 10px 0 10px;
	padding: 0 10px 0 10px;
	display: grid;
	gap: .3125em .75em;
	grid-template-columns: 1em minmax(5em, 1fr) 2.5em;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
	line-height: 1.25;
}
.main-homepage .player-row:hover {
	border: 1px solid #CC9D70;
	border-radius: 4px;
	background: rgba(0,0,0, 1);
}
.main-homepage .logged-in-player-row {
	margin: 0 10px 0 10px;
	padding: 0 10px 0 10px;
	display: grid;
	gap: .3125em .75em;
	grid-template-columns: 1em minmax(5em, 1fr) 2.5em;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
	line-height: 1.25;
	color: #CB0906;
}
.main-homepage .four-digit {
	margin: 0 10px 0 10px;
	padding: 0 10px 0 10px;
	display: grid;
	gap: .3125em .75em;
	grid-template-columns: 1.5em minmax(5em, 1fr) 2.5em;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
	line-height: 1.25;
	color: #CB0906;
}
.main-homepage .logged-in-player-row:hover {
	border: 1px solid #CC9D70;
	border-radius: 4px;
	background: rgba(0,0,0, 1);
}

.leaderboard-column .rank {
	padding-top: .375em;
	padding-bottom: .375em;
	color: rgba(255, 217, 140, 0.7);
	justify-self: end;
}
.leaderboard-column .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9
	 {
	padding-top: .375em;
	padding-bottom: .375em;
	color: #CC9D70;
	justify-self: start;
}

.leaderboard-column .total,
.standings .player-total,
.standings .player2-total,
.standings .player3-total,
.standings .player4-total,
.standings .player5-total,
.standings .player6-total,
.standings .player7-total {
	padding-top: .375em;
	padding-bottom: .375em;
	color: #FFD98C;
	justify-self: end;
	cursor: pointer;
}
/*-------------------------------------------------to make logged in players green on index pages*/
.leaderboard-column .logged-in-player-row .rank {
	color: #00B979;
}
.leaderboard-column .logged-in-player-row .player,
					.player2,
					.player3,
			 .player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9 {
	color: #00B979;
}
.leaderboard-column .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9 a {
	color: #00B979;
	text-decoration: none;
}
.leaderboard-column .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9 a:link,
.leaderboard-column .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9 a:visited {
	color: #00B979;
	text-decoration: none;
}
.leaderboard-column .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9 a:hover, 
a:focus {
	color: #00B979;
	text-decoration: none;
}
.leaderboard-column .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9 a:active {
	color: #00B979;
	text-decoration: none;
}
.leaderboard-column .logged-in-player-row .total {
	color: #00B979;
}

.main-homepage .not-entered-player-row	{
	margin: 0 10px 0 10px;
	padding: 0 10px 0 10px;
	display: grid;
	gap: .3125em .75em;
	grid-template-columns: minmax(8em, 1fr) 4em;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
}
.main-homepage .total-player-row	{
	margin: 0 10px 0 10px;
	padding: 0 10px 0 10px;
	display: grid;
	gap: .3125em .75em;
	grid-template-columns: 1fr;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
}
.not-entered-column {
	font-style: italic;
	padding-top: .375em;
	color: #CC9D70;
	line-height: 1.25;
	justify-self: start;
}
.total-player-count {
	font-style: italic;
	padding-top: .375em;
	color: #CC9D70;
	line-height: 1.25;
	justify-self: start;
}
.main-homepage .full-standings-row	{
	margin: 0 10px 0 10px;
	padding: 0 10px 10px 10px;
	display: grid;
	gap: 0 .75em;
	grid-template-columns: 1fr;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
}
.see-full-standings {
	font-weight: 600;
	padding-top: .375em;
	color: #CC9D70;
	text-transform: uppercase;
	line-height: 1.25;
	justify-self: center;
}
.see-full-standings a {
	color: #FFD98C;
}
.see-full-standings a:hover,
.see-full-standings a:focus {
	color: #CC9D70;
}
.main-homepage .log-in {
	font-weight: 600;
	font-style: normal;
}
.leaderboard-column .total .outlined-button-smaller {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		padding-left: .3em;
		padding-right: .3em;
		padding-top: .15em;
		padding-bottom: .2em;
		text-transform: uppercase;
		font-size: .75rem;
		background: #000;
		color: #fff;
		cursor: pointer;
}

.leaderboard-column .total .outlined-button-smaller:hover, 
.leaderboard-column .total .outlined-button-smaller:focus	{
		animation: glowyellow 500ms;
		-moz-animation: glowyellow 500ms;
		-webkit-animation: glowyellow 500ms;
		-o-animation: glowyellow 500ms;
		animation-fill-mode: forwards;
}

/*-------------------------------------------------STANDINGS STYLES----*/


.standings {
	max-width: 825px;
	padding: 5px 20px 20px 20px;
	background-image: linear-gradient(230deg, #0c171c 41% 57%, #1b95b2 180%);			/*(230deg, #0D1E24, #114350);*/
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	border-radius: 8px;
	box-shadow: 2px 2px 2px #CC9D70;
	border-top: 1px solid #CC9D70;
}

.standings .back-to-top {
	color: #00B979;
	text-align: right;
}
.standings .back-to-top img {
	margin-left: .25em;
	padding-bottom: .25em;
	vertical-align: middle;
	width: 15px;
}
.standings .standings-header img,
.make-picks-container .contest-details .contest-button-container img {
	margin-left: 1em;
	padding-bottom: .25em;
	vertical-align: middle;
	width: 18px;
}
.standings .nhc-toggle {
	color: #00B979 !important;
	cursor: pointer;
}

.standings-advertisement a {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: auto;
}
.standings-google-adsense {
	display: flex;
	justify-content: center;
	margin: 6px auto;
	/*border: 1px solid #fff;*/
}
.standings-google-adsense-701-886 {
	display: none;
	justify-content: center;
	margin: 6px auto;
	/*border: 1px solid #fff;*/
}
.standings-google-adsense-700-or-less {
	display: none;
	justify-content: center;
	margin: 6px auto;
	/*border: 1px solid #fff;*/
}
.contest-title-row
.contests-column {
	padding: 5px 0 20px;
}
.homepage-header,
.make-picks-header,
.contests-header {
	width: 100%;
	font-size: 1.5em;
	padding-top: .375em;
	padding-bottom: .375em;
	margin-bottom: .5em;
	color: #FFFFFF;
	text-transform: uppercase;
	font-weight: 600;
	text-align: center;
	border: 1px solid #CC9D70;
	border-radius: 8px;
}
/*=================CONTEST BUTTON CONTAINER ANIMATION=========================================*/
@keyframes button-toggle-animation {
		0% { background-color: #0C171C; color: #ffffff; }
		100% { background-color: #122833; color: #CC9D70; }
}
/*==========================================================*/

.contest-button-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: .375em;
	margin-bottom: .375em;
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
	font-size: 1.25em;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
}
.contest-button-container:hover,
.contest-button-container:focus {
		animation: button-toggle-animation 300ms;
		-moz-animation: button-toggle-animation 300ms;
		-webkit-animation: button-toggle-animation 300ms;
		-o-animation: button-toggle-animation 300ms;
		animation-fill-mode: forwards;
}
.contest-have-not-entered {
	font-size: 1.25em;
	color: #00B979;
	margin-bottom: 1.5em;
}
.contests-row a {
	text-decoration: none;
}
.contests-row a:link {
	text-decoration: none;
}
.contests-row a:visited {
}
.contests-row a:hover, a:focus {
}
.contest1-button {
	display: flex;
	justify-content: space-between;
	margin: 0;
}
.changeCarat {

}
/*---IMG below is to format images in markdown on Contests page--------------*/

img[src*="#squeeze"] {
   width:330px;
}
/*--------------------------------------SIDE NAV STUFF--------------*/

.side-nav-button-wrapper {
	position: relative;
	width: 100%;
	margin: 0 0 -1px 0;
	border-top: 1px solid #1b95b2;
	border-bottom: 1px solid #1b95b2;
	border-left: 1px solid #1b95b2;
	border-right: 1px solid #1b95b2;
	border-radius: 0;
	color: #fff;
	background-color: #0C171C;
}
.side-nav-button-wrapper:first-of-type {
	border-radius: 8px 8px 0 0;
}
.side-nav-button-wrapper:last-of-type {
	border-radius: 0 0 8px 8px;
}
.side-nav-button-wrapper:hover,
.side-nav-button-wrapper:focus {
	border: 1px solid #0ECBE6;
	z-index: 102;
}
.side-nav-button-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 4.5em;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
	font-size: 1em;
	cursor: pointer;
}
.side-nav-button-container:hover,
.side-nav-button-container:focus {
		animation: side-nav-button-toggle-animation 300ms;
		-moz-animation: side-nav-button-toggle-animation 300ms;
		-webkit-animation: side-nav-button-toggle-animation 300ms;
		-o-animation: side-nav-button-toggle-animation 300ms;
		animation-fill-mode: forwards;
}
/*=================CONTEST BUTTON CONTAINER ANIMATION=========================================*/
@keyframes side-nav-button-toggle-animation {
		0% { background-color: #0C171C; color: #ffffff; }
		100% { background-color: #0C171C; color: #CC9D70; }
}
/*==========================================================*/
.side-nav-text {
	vertical-align: middle;
	justify-self: center;
}
.side-nav-carat-container {
	vertical-align: middle;
	justify-self: center;
}
.side-nav-heading-ital {
	font-family: "Myriad Pro", sans-serif;
	font-weight: 600;
	font-style: italic;
	font-size: 22px;
	color: #fff;
	margin: 30px 0 15px;
	padding-left: 3px;
}
.side-nav-heading-ital:first-of-type {
	margin: 60px 0 15px;
	padding-left: 5px;
}

.side-nav-carat {
	width: 14px;
}
.side-nav-contest1-button {
	display: flex;
	justify-content: space-between;
	margin: 0;
}
.side-nav-contest1-reveal, 
.side-nav-contest2-reveal,
.side-nav-contest3-reveal,
.side-nav-contest4-reveal,
.side-nav-contest5-reveal, 
.side-nav-contest6-reveal,
.side-nav-contest7-reveal,
.side-nav-contest8-reveal {
	display: none;
}

.side-nav-sub-button-container {
	display: flex;
	justify-content: space-evenly;
	margin: .25em 1em 1.25em 1em;
}
.random-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-top: .375em;
	padding-bottom: .375em;
	padding-left: 1rem;
	padding-right: 1rem;
	border: 1px solid #00B979;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
	font-size: 1em;
	text-align: center;
	cursor: pointer;
	height: 3em;
}
.random-button-container:hover,
.random-button-container:focus {
		animation: button-toggle-animation 300ms;
		-moz-animation: button-toggle-animation 300ms;
		-webkit-animation: button-toggle-animation 300ms;
		-o-animation: button-toggle-animation 300ms;
		animation-fill-mode: forwards;
}
.random-text {
	line-height: 1.725em;
	vertical-align: middle;
}



/*-----------------------------------------------------*/


.contest-details {
	max-width: 825px;
	margin: 0 auto .75em;
	border: 1px solid #CB0906;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
}

.contests-container .contest-details .contest-button-container {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: auto;
}
.make-picks-container .contest-details .contest-button-container {
		margin-top: 0;
		margin-bottom: 0;
		/*justify-content: center;*/
		flex-wrap: wrap;
}

.contest1-reveal, 
.contest2-reveal,
.contest3-reveal,
.contest4-reveal,
.contest5-reveal, 
.contest6-reveal,
.contest7-reveal,
.contest8-reveal {
	display: none;
}

.make-picks-container .contest-details .contest1-reveal {
	display: block;
}



.contest1-rules-reveal, 
.contest2-rules-reveal,
.contest3-rules-reveal,
.contest4-rules-reveal {
	display: none;
}
.contest-detail-button-container {
	display: flex;
	justify-content: space-evenly;
	margin: .25em 1.25em 1.25em 1.25em;
}
/*.outlined-button-highlighted {		THIS IS FOR THE WHITE SHADOW BUTTON IF YOU NEED IT
		border: 1px solid #000;
		box-shadow: 2px 2px 2px #fff;
		border-radius: 4px;
		background-color: #CC9D70;
		padding-left: .3em;
		padding-right: .3em;
		padding-top: .1em;
		padding-bottom: .1em;
		text-transform: uppercase;
		font-size: 1rem;
}
*/
/*=================SAVE CHANGES / SUBMIT and OUTLINED BUTTON ANIMATION=========================================*/
@keyframes glowyellow {
		0% { box-shadow: 0 0 0px #FFD98C; color: #CC9D70; }
		100% { box-shadow: 0 0 4px #FFD98C; color: #FFD98C; }
		0% { color: #fff;	}
		100% { color: #FFD98C; }
}
/*==========================================================*/

.outlined-button {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		padding-left: .3em;
		padding-right: .3em;
		padding-top: .1em;
		padding-bottom: .2em;
		text-transform: uppercase;
		font-size: 1.25rem;
		background: #000;
		color: #fff;
		cursor: pointer;
}
.outlined-button a:link, 
.outlined-button a:visited	{
	color: #fff;
}
.outlined-button:hover, 
.outlined-button:focus	{
		animation: glowyellow 500ms;
		-moz-animation: glowyellow 500ms;
		-webkit-animation: glowyellow 500ms;
		-o-animation: glowyellow 500ms;
		animation-fill-mode: forwards;
}
.outlined-button-smaller {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		padding-left: .3em;
		padding-right: .3em;
		padding-top: .15em;
		padding-bottom: .2em;
		text-transform: uppercase;
		font-size: 1rem;
		background: #000;
		color: #fff;
		cursor: pointer;
}
.outlined-button-smaller:hover, 
.outlined-button-smaller:focus	{
		animation: glowyellow 500ms;
		-moz-animation: glowyellow 500ms;
		-webkit-animation: glowyellow 500ms;
		-o-animation: glowyellow 500ms;
		animation-fill-mode: forwards;
}



.contest-format {
	margin: 0 .625em;
	padding: .625em 0 0 0;
	display: grid;
	gap: .3125em .5em;
	grid-template-columns: 5em 1fr;
	grid-template-rows: auto;
	border-top: 1px solid rgba(255,255,255, .5) ;
}
.format-word {
	font-weight: 600;
	color: #CC9D70;
	text-transform: uppercase;
	text-align: right;
	justify-self: end;
}
.format-text {
	color: white;
	text-align: left;
	justify-self: start;
}
.contest-prizes {
	margin: 0 .625em;
	padding: .625em 0 0 0;
	display: grid;
	gap: .3125em .5em;
	grid-template-columns: 5em 1fr;
	grid-template-rows: auto;
}
.contest-prizes-word {
	font-weight: 600;
	padding-top: .625em;
	color: #CC9D70;
	text-transform: uppercase;
	text-align: right;
	justify-self: end;
	border-top: 1px solid rgba(255,255,255, 0);
}
.contest-prizes-text {
	padding-top: .625em;
	width: 100%;
	color: white;
	text-align: left;
	justify-self: start;
	border-top: 1px solid rgba(255,255,255, .5);
}
.contest-schedule {
	margin: 0 .625em;
	padding-top: .625em;
	display: grid;
	gap: .3125em .5em;
	grid-template-columns: 5em 1fr;
	grid-template-rows: auto;
}
.contest-schedule-word {
	font-weight: 600;
	padding-top: .625em;
	color: #CC9D70;
	text-transform: uppercase;
	text-align: right;
	justify-self: end;
	border-top: 1px solid rgba(255,255,255, 0);
}
.contest-schedule-text {
	padding-top: .625em;
	width: 100%;
	color: white;
	text-align: left;
	justify-self: start;
	border-top: 1px solid rgba(255,255,255, .5);
}
.contest-entry {
	margin: 0 .625em;
	padding-top: .625em;
	display: grid;
	gap: .3125em .5em;
	grid-template-columns: 5em 1fr;
	grid-template-rows: auto;
}
.contest-entry-word {
	font-weight: 600;
	padding-top: .625em;
	color: #CC9D70;
	text-transform: uppercase;
	text-align: right;
	justify-self: end;
	border-top: 1px solid rgba(255,255,255, 0);
}
.contest-entry-text {
	padding-top: .625em;
	width: 100%;
	color: white;
	text-align: left;
	justify-self: start;
	border-top: 1px solid rgba(255,255,255, .5);
}
.contest-rules {
	margin: 0 .625em;
	padding-top: .8em;
/*	display: grid;
	gap: .3125em .5em;
	grid-template-columns: 5em 1fr;
	grid-template-rows: auto;
*/}
.contest-rules-word {
	font-weight: 600;
	padding-top: .625em;
	padding-bottom: 1em;
	color: #CC9D70;
	text-transform: uppercase;
	text-align: center;
	/*justify-self: end;*/
	border-top: 1px solid rgba(255,255,255, .5);
	cursor: pointer;
}
.contest-rules-text {
	padding-top: .625em;
	padding-bottom: 1em;
	width: 100%;
	color: white;
	text-align: left;
	justify-self: start;
}
.contest-prizes-text p,
.format-text p,
.contest-entry-text p {
	margin-bottom: 1em;
}
.contest-prizes-text p:last-of-type, 
.format-text p:last-of-type,
.contest-entry-text p:last-of-type {
	margin-bottom: 0;
}
.boxed-more {
		font-size: 1em;
		border-radius: 4px;
		padding-top: 0px;
		padding-right: 3px;
		padding-bottom: 1px;
		padding-left: 3px;
		background: #CC9D70 0% 0% no-repeat padding-box;
		color: red;
		text-transform: uppercase;
	}
.contest-rules-word a {
	text-decoration: none;
	color: #CC9D70;
}
.contest-rules-word a:link {
	color: #CC9D70;
}
.contest-rules-word a:visited {
	color: #CC9D70;
}
.contest-rules-word a:hover, 
										a:focus	{
	color: #fff;
}
.complete-rules {
	background: rgba(255,255,255, .9);
	margin: 0 10px 10px;
	padding: 15px 20px .1em;
	border-radius: 8px;
}
.complete-rules p {
	line-height: 1.5;
	color: #000;
	margin-bottom: 1em;
	text-align: left;
}

/*================================================SIGNIN SECTION*/


#signin-checkbox {
	position: absolute;
	top: 12px;
	right: 10px;
	opacity: 0;
}

#signin-checkbox:checked ~ .signin-container {
	top: 0px;
}

.signin-container {
	position: absolute;
	right: 10px;
	top: -370px;
	z-index: 102;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	transition: all .2s ease;
	max-width: 22em;
}
.signin-card {
	width: 96%;
	/*flex-grow: 1;*/
	/*margin-top: .75em;*/
	margin-bottom: .75em;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid rgba(0,0,0, 0);
	border-radius: 8px;
	color: #fff;
	background-image: -webkit-linear-gradient(140deg, #0c171c 41%, #1b95b2 180%);
	background-image: -o-linear-gradient(140deg, #0c171c 41%, #1b95b2 180%);
	background-image: linear-gradient(230deg, #0c171c 41%, #1b95b2 180%); 
	box-shadow: 2px 2px 2px #CC9D70;
	}
.signin-info-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: .75rem;
	padding-bottom: .75rem;
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 8px;
	color: #fff;
	font-size: 1.25em;
}
.signin-close-button img {
	width: 30px;
	cursor: pointer;
}
.break-flex-row {	/*This is only needed if you have to manually break a row in flex item*/
	flex-basis: 100%;
	height: 0;
	/*border: 1px solid #fff;*/
}
.signin-title-text {
	color: #fff;
	justify-content: center;
	flex-basis: 100%;
	text-align: center;
	margin: .5em 0 .5em 0;
}
.signin-text-field-container {
	margin: .25em 0 0 0;
}
.signin-form {
	flex-basis: 100%;
}
/*input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea {
	display: block;
	width: 100%;
	background: #eee;
	border: none;
	border-radius: 8px;
	padding: 10px;
	margin-bottom: 20px;
	font-size: 1.25em;
}
*/.signin-text-field-container input[type="text"],
.signin-text-field-container input[type="email"],
.signin-text-field-container input[type="password"],
.signin-text-field-container textarea {
		font-size: 1em; 
		line-height: 1.25em;
		margin-bottom: .75em;
		border: 1px solid #CB0906;
		border-radius: 4px;
		color: #CC9D70;
		background: #000;
		transition: box-shadow .2s;
		-webkit-appearance: none;
		outline: none;
}
.signin-text-field-container textarea {
	resize: vertical;
	}
.signin-text-field-container input[type="text"]:hover,
.signin-text-field-container input[type="email"]:hover,
.signin-text-field-container input[type="password"]:hover,
.signin-text-field-container textarea:hover {
	box-shadow: 0 0 4px #FFD98C;
}
.signin-text-field-container input[type="text"]:focus,
.signin-text-field-container input[type="email"]:focus,
.signin-text-field-container input[type="password"]:focus,
.signin-text-field-container textarea:focus {
	box-shadow: 0 0 4px #FFD98C;
}
.signin-text-field-container input[type="text"]:active,
.signin-text-field-container input[type="email"]:active,
.signin-text-field-container input[type="password"]:active,
.signin-text-field-container textarea:active {
	box-shadow: 0 0 4px #FFD98C;
}
.signin-text-field-container input[type="text"]::placeholder,
.signin-text-field-container input[type="email"]::placeholder,
.signin-text-field-container input[type="password"]::placeholder {
	font-size: 1em;
	color: #CC9D70;
	opacity: .5;
	font-weight: 400;
}
.signin-card .save-changes-container {
	display: flex;
	justify-content: space-evenly;
	margin: 1em 0 1.25em 0;
}

/*================================================REGISTRATION SECTION*/

.registration-account-column {
	padding: 0 0 20px;
	/*display: grid;*/	
}
.registration-info-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
	font-size: 1.25em;
	cursor: pointer;
}
/*.account-button-container:hover {
		animation: button-toggle-animation 300ms;
		-moz-animation: button-toggle-animation 300ms;
		-webkit-animation: button-toggle-animation 300ms;
		-o-animation: button-toggle-animation 300ms;
		animation-fill-mode: forwards;
	border: none;
}
*/
/*.registration-details {
		margin-left: auto;
		margin-right: auto;
} 
*/
.registration-flex-container {

}
.registration-details {
	width: 100%;
	/*flex-grow: 1;*/
	margin-top: .75em;
	margin-bottom: .75em;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #CB0906;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
}
.registration-info-container,
.registration-form-container,
.account-wrapper {
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
}
/*.registration-form-container {
	margin: .25em 1.25em 0em 1.25em;
} */
.register-title-text {
	color: #CC9D70;
}
.register-title-text a {
	color: #fff;
}
.registration-text {
	font-size: 1em;
	color: #fff;
	font-weight: 400;
	line-height: 1.5;
	padding: 0 0 .75em 1px;
}

.registration-text-field-container {
	margin: .25em 0 0 0;
}
.registration-text-field-container input[type="text"],
.registration-text-field-container input[type="email"],
.registration-text-field-container textarea {
	
}
.registration-text-field-container textarea {
	resize: vertical;
	}
.registration-text-field-container input[type="text"]:focus,
.registration-text-field-container input[type="email"]:focus,
.registration-text-field-container textarea:focus,
.registration-text-field-container select:focus {
	outline: none;
	/*box-shadow: 2px 2px 4px #FFD98C;*/
}
.registration-text-field-container input[type="text"]:hover,
.registration-text-field-container input[type="email"]:hover,
.registration-text-field-container textarea:hover {
	/*box-shadow: 2px 2px 4px #FFD98C;*/
}


.registration-text-field-container input[type="text"]::placeholder,
.registration-text-field-container input[type="email"]::placeholder {
	font-size: 1em;
	color: rgba(0,0,0, .3);
	font-weight: 400;
}
.save-changes-container input[type="submit"]:focus {
	outline: none;
}

/*================= REGISTRATION BUTTON ANIMATION=========================================*/
@keyframes boxglow {
		0% { box-shadow: 0 0 0px #FFD98C; color: #CC9D70; }
		100% { box-shadow: 0 0 4px #FFD98C; color: #FFD98C; }
		0% { box-shadow: 0 0 0px #FFD98C; color: #CC9D70; }
	}
/*==========================================================*/

/*.outlined-button-make-picks:hover {
		animation: glow 500ms;
		-moz-animation: glow 500ms;
		-webkit-animation: glow 500ms;
		-o-animation: glow 500ms;
		animation-fill-mode: forwards;
}
*/

.submit-password {
	position: relative;
	left: 0vw;
	top: 0;
	opacity: 1;
}
.submit-password-off-screen {
	position: relative;
	left: -100vw;
	top: 0;
	opacity: 0;
}
.confirm-password {
	position: relative;
	left: 100vw;
	top: 0;
	opacity: 0;
}
.confirm-password-in-place {
	position: relative;
	left: 0vw;
	top: 0;
	opacity: 1;
}
.confirm-password-off-screen {
	position: relative;
	left: -100vw;
	top: 0;
	opacity: 0;
}
.player-name-submit {
	position: relative;
	left: 100vw;
	top: 0;
	opacity: 0;
}
.player-name-in-place {
	position: relative;
	left: 0vw;
	top: 0;
	opacity: 1;
}
.player-name-off-screen {
	position: relative;
	left: -100vw;
	top: 0;
	opacity: 0;
}
.real-name-submit {
	position: relative;
	left: 100vw;
	top: 0;
	opacity: 0; 
}
.real-name-in-place {
	position: relative;
	left: 0vw;
	top: 0;
	opacity: 1;
}
.real-name-off-screen {
	position: relative;
	left: -100vw;
	top: 0;
	opacity: 0;
}
.transform1 {
	-webkit-transition: all .25s ease-out;	
	-moz-transition: all .25s ease-out;	
	-o-transition: all .25s ease-out;	
	-ms-transition: all .25s ease-out;	
	transition: all .25s ease-out;
}
.transform2 {
	-webkit-transition: all .25s ease-in;	
	-moz-transition: all .25s ease-in;	
	-o-transition: all .25s ease-in;	
	-ms-transition: all .25s ease-in;	
	transition: all .25s ease-in;
}


/*.transform {
		animation: slideFromRight 0.8s;
		-moz-animation: slideFromRight 0.8s;
		-webkit-animation: slideFromRight 0.8s;
		-o-animation: slideFromRight 0.8s;
		animation-fill-mode: forwards;
}
*/

/*@keyframes slideFromRight {
	from {
		opacity: 0;
		left: 50vw; }
	to {
		opacity: 1;
		left: 0; } 
}
*/


/*================================================ END REGISTRATION SECTION*/





.account-container {

}
.account-details {
	width: 100%;
	margin-top: .75em;
	margin-bottom: .75em;
	border: 1px solid #CB0906;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
}
.account1-button {
	display: flex;
	justify-content: space-between;
	margin: 0;
}
.account-button-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 8px;
	color: #fff;
	background-color: #0C171C;
	font-size: 1.25em;
	text-align: center;
	cursor: pointer;
}
.account-intro-text {
	font-size: 1.25em;
	text-align: center;
}

.account-button-container:hover {
		animation: button-toggle-animation 300ms;
		-moz-animation: button-toggle-animation 300ms;
		-webkit-animation: button-toggle-animation 300ms;
		-o-animation: button-toggle-animation 300ms;
		animation-fill-mode: forwards;
	border: none;
}
.account-picture-container {
	display: flex;
	justify-content: space-evenly;
	margin: .25em 1.25em 1.25em 1.25em;
}
.account-detail-container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin: .25em 1.25em 1.25em 1.25em;
}
.account-detail-container-edit {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin: .25em 1.25em 1.25em 1.25em;
}
.account-block {

}
.account-intro-title-text {
	font-size: 1.25em;
	color: #CC9D70;
	line-height: 1.5;
	font-weight: 400;
}
.account-text-row {
	text-align: left;
}
.account-page-text {
	width: 100%;
	font-size: 1.25em;
	color: #CC9D70;
	font-weight: 600;
	/*padding: 0 1rem;*/
}
.account-page-white-text {
	width: 100%;
	font-size: 1em;
	color: #fff;
	font-weight: 400;
	line-height: 1.3;
	/*padding: 1rem 1rem .25em;*/
}
.email-prefs-container {
	display: flex;
	justify-content: space-between;
	/*width: 100%;*/
	padding-bottom: 1.75rem;
	padding-left: 1em;
	padding-right: 1em;
}
/*.email-prefs-checkbox {
	vertical-align: top;
	padding-top: 0;
	align-content: top;
}
*//*input[type="checkbox"] {
	-ms-transform: scale(2); 
	-moz-transform: scale(2); 
	-webkit-transform: scale(2, 2);
	-o-transform: scale(2); 
	transform: scale(2, 2);
	margin: 8px 15px 0 8px;
}
*/


/*=======================================BEGIN CHECKBOXES*/
.email-prefs-checkbox {
	display: flex;
	padding-top: 0;
}
/*.checkbox {
		position: relative;
}
*/
/*.email-prefs-checkbox > span {
		color: #34495E;
		padding: 0.5rem 0.25rem;
}
*/
		/* Note, you may want to position the checkbox over top the label and set the opacity to zero instead. It can be better for accessibilty on some touch devices for discoverability. */
.email-prefs-checkbox label {
		/*padding: 10px;*/
		height: 1.5rem;
		width: 1.5rem;
		margin-right: 15px;
		margin-left: 4px;
		border: 1px solid rgba(255,255,255, 1);
		border-radius: 4px;
		outline: none;
		cursor: pointer;
}
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
	position: absolute;
	left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
	position: relative;
	/*padding-left: 1.95em;*/
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before/*,
[type="checkbox"]:checked + label:before*/ {
	content: '\2713';
	position: absolute;
	left: -1px; top: -1px;
	color: rgba(255,255,255, 0);
		height: 1.5rem;
		width: 1.5rem;
		text-align: center;
	/*	margin-right: 15px;
		margin-left: 4px;
*/		border: 1px solid #CC9D70;
		border-radius: 4px;
		outline: none;
		transition-duration: 0.2s;
		background-color: #000;
		cursor: pointer;
}
 /*checked mark aspect */
/*[type="checkbox"]:not(:checked) + label:after,*/
[type="checkbox"]:checked + label:after {
	content: '\2713';
	position: absolute;
	left: -1px; top: -1px;
	color: rgba(255,255,255, 1);
		height: 1.5rem;
		width: 1.5rem;
		text-align: center;
		/*margin-right: 15px;
		/*margin-left: 4px;*/
		border: 1px solid #CC9D70;
		border-radius: 4px;
		outline: none;
		/*transition-duration: 0.2s;*/
		background-color: #98050F;
		cursor: pointer;
		font-size: 1.9em;
		line-height: 1.5rem;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
 /* opacity: 0;
	transform: scale(0);
*/		animation: checkboxback 0.2s;
		-moz-animation: checkboxback 0.2s;
		-webkit-animation: checkboxback 0.2s;
		-o-animation: checkboxback 0.2s;
		animation-fill-mode: forwards;
}
[type="checkbox"]:checked + label:after {
/*	opacity: 1;
	transform: scale(1);
*/		animation: checkboxtransform 0.2s;
		-moz-animation: checkboxtransform 0.2s;
		-webkit-animation: checkboxtransform 0.2s;
		-o-animation: checkboxtransform 0.2s;
		animation-fill-mode: forwards;
}
@keyframes checkboxtransform {
		0% { background-color: #000000;	}
		100% { background-color: #98050F; }
}
@keyframes checkboxback {
		0% { background-color: #98050F;	}
		100% { background-color: #000000; }
}


/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
	border: 2px dotted blue;
}
/*=======================================END CHECKBOXES*/


input[type="submit"]:enabled {
		border: 1px solid rgba(204,157,112, 1);
		border-radius: 4px;
		padding-left: .3em;
		padding-right: .3em;
		padding-top: .15em;
		padding-bottom: .2em;
		text-transform: uppercase;
		font-size: 1.25rem;
		background: #000;
		color: rgba(255,255,255, 1);
		cursor: pointer;
}

input[type="submit"]:disabled {
		border: 1px solid rgba(204,157,112, .5);
		border-radius: 4px;
		padding-left: .3em;
		padding-right: .3em;
		padding-top: .15em;
		padding-bottom: .2em;
		text-transform: uppercase;
		font-size: 1.25rem;
		background: #000;
		color: rgba(255,255,255, .5);
}

input[type="submit"]:enabled:hover {
		animation: glowyellow 500ms;
		-moz-animation: glowyellow 500ms;
		-webkit-animation: glowyellow 500ms;
		-o-animation: glowyellow 500ms;
		animation-fill-mode: forwards;
}
 /*Back button on Registration flow */

.back-button input[type="button"]:enabled {
		border: 0 solid #CC9D70;
		border-radius: 0;
		padding-left: .3em;
		padding-right: .3em;
		padding-top: .15em;
		padding-bottom: .2em;
		text-transform: uppercase;
		font-size: .75rem;
		background: #0C171C;
		color: #fff;
		cursor: pointer;
}

 /*End Back button on Registration flow */

.account-text-field-container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin: .25em 1.25em 0em 1.25em;
}

.prefs-text {
	font-size: 1em;
	color: #fff;
	font-weight: 400;
	line-height: 1.3;
	padding: 0 0 .25em 1px;
}
.text-field-container {
	margin: .25em 0 0 0;
}

input[type="text"],
input[type="select"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea {
	display: block;
	width: 100%;
	background: #eee;
	border: none;
	border-radius: 8px;
	padding: 10px;
	margin-bottom: 20px;
	font-size: 1.25em;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder {
	font-size: 1em;
	color: #CC9D70;
	font-weight: 600;
	opacity: 1;
	/*line-height: normal;*/
}
input[type="number"] {
	max-width: 10em;
}
/*TO REMOVE THE SPINNER COUNTER ON NUMBER INPUT FIELD	Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}
/*TO REMOVE THE SPINNER COUNTER ON NUMBER INPUT FIELD	Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.state-menu,
.heard-menu,
.country-menu {
	display: block;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .4em;
	width: 100%;
	max-width: 100%;
	margin: 0 0 20px 0;
	border: 1px solid #aaa;
	border-radius: 8px;
	font-size: 1.25em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
		linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;	/*This right .7em is to position the arrow down*/
	background-size: .65em auto, 100%;
}
.country-menu {
	display: block;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .4em;
	width: 100%;
	max-width: 100%;
	margin: 0 0 20px 0;
	border: 1px solid #aaa;
	border-radius: 8px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
		linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;	/*This right .7em is to position the arrow down*/
	background-size: .65em auto, 100%;

}
.heard-menu {
	display: block;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .4em;
	width: 100%;
	max-width: 100%;
	margin: 0 0 20px 0;
	border: 1px solid #aaa;
	border-radius: 8px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
		linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;	/*This right .7em is to position the arrow down*/
	background-size: .65em auto, 100%;
}

.save-changes-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: 0 1.25em 1.25em 1.25em;
}
.registration-form-container .save-changes-container {
	display: flex;
	justify-content: space-between;
	margin: 0 1.25em 1.25em 1.25em;
}

/*.account1-reveal, */
.account2-reveal,
.account3-reveal,
.account4-reveal,
.account5-reveal,
.account6-reveal,
.account7-reveal,
.account8-reveal,
.account9-reveal {
	display: none;
}

.player-pic-icon {
	width: 78px;
}



.standings .player-row ol {
		margin: 0;
		padding: 0;
}
.standings .player-row a {
	text-decoration: none;
}
.standings .player-row a:link, 
.standings .player-row a:visited {
	color: #CC9D70;
}
.standings .player-row a:hover {
	color: #FFD98C;
}
.standings .player-row a:focus {
	color: #CC9D70;
}
.standings .player-row a:active {
	color: #CC9D70;
}

.account-container a {
	text-decoration: none;
}
.account-container a:link, 
.account-container a:visited {
	color: #CC9D70;
}
.account-container a:hover, 
.account-container a:focus {
	color: #FFD98C;
}

/*------------------------------------------------styles for green on standings*/
.standings .logged-in-player-row .rank {
	color: #00B979;
}
.standings .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
.standings .logged-in-player-row .player {
	color: #00B979;
}
.standings .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
.standings .logged-in-player-row .player a {
	color: #00B979;
	text-decoration: none;
}
.standings .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
.standings .logged-in-player-row .player a:link,
.standings .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
.standings .logged-in-player-row .player a:visited {
	color: #00B979;
	text-decoration: none;
}
.standings .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
.standings .logged-in-player-row .player a:hover, 
a:focus {
	color: #00E394;
	text-decoration: none;
}
.standings .logged-in-player-row .player,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
					.player9,
					.player0,
					.player1,
					.player2,
					.player3,
					.player4,
					.player5,
					.player6,
					.player7,
					.player8,
.standings .logged-in-player-row .player a:active {
	color: #00E394;
	text-decoration: none;
}
.standings .logged-in-player-row .total {
	color: #00B979;
}
.nhc-tour-logo {	
	width: 22px;
	margin-left: .4em;
	margin-bottom: .2em;
	opacity: 1;
}
.nhc-qualify1 {	
	width: 26px;
	margin-left: .4em;	
	margin-bottom: .1em;
}
.nhc-qualify2 {	
	width: 31px;
	margin-left: .4em;	
	margin-bottom: .1em;
}
.horse-favorite-icon {
	width: 22px;
	margin-left: .3em;
	margin-bottom: .2em;
	opacity: 1.2;
}
.horse-favorite-icon:hover {
	filter: brightness(130%) saturate(110%);
	text-decoration:none;
}
.horse-favorite-activated {
	filter: brightness(130%) saturate(110%);
}

/*--------------------------------------------------------end styles for green standings*/



.standings .outlined-red-buttons-row {
	display: flex;
	/*flex-wrap: wrap;*/
	justify-content: space-between;
	margin: 0 0 1.25em 0;
	/*align-content: top;*/
	align-items: baseline;
}
.outlined-button-make-picks {
		border: 1px solid #CB0906;
		border-radius: 4px;
		margin: 3px 1em 0 0;
		padding-left: .5em;
		padding-right: .5em;
		padding-top: .25em;
		padding-bottom: .3125em;
		font-size: 1.125em;
		text-align: center;
		flex-basis: 12em;
		color: #CC9D70;
		background: #000;
		cursor: pointer;
}
.outlined-button-player-search {
		border: 1px solid #CB0906;
		border-radius: 4px;
		background: #000;
		margin-top: 3px;
		padding-left: .75em;
		padding-right: .75em;
		padding-top: .25em;
		padding-bottom: .3125em;
		font-size: 1.125em;
		flex-grow: 1;
		/*cursor: pointer;*/
}
.outlined-button-player-search input[type="search"] {
		width: 100%;
		margin: 0;
		padding: 0;
		background: #000;
		color: #FFD98C;
		border: 0;
		outline: none;
		-webkit-appearance: none;
}
.outlined-button-player-search input[type="search"]::placeholder {
		color: #CC9D70;
		opacity: .8;
		/*line-height: normal;*/	/*- this makes the alignment go off for firefox desktop*/ 
}
.outlined-button-race-timer {
		border: 1px solid #CB0906;
		border-radius: 4px;
		background: #000;
		margin: 3px 0 0 1em;
		padding-left: .5em;
		padding-right: .5em;
		padding-top: .25em;
		padding-bottom: .3125em;
		font-size: 1.125em;
		text-align: center;
		flex-basis: 13em;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: baseline;
		position: relative;
}
.timertip {
	visibility: hidden;
	/*width: 13em;*/
	background-color: #000;
	color: #FFD98C;
	text-align: center;
	border-radius: 6px;
	border: 1px solid #CC9D70;
	padding: .375em .5em;
	position: absolute;
	left: center;
	top: -3.5em;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
}
.timertip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #FFD98C transparent transparent transparent;
	z-index: 1;
}

/*=================BUTTON ANIMATION=========================================*/
@keyframes glow {
		0% { box-shadow: 0 0 0px #FFD98C; color: #CC9D70; }
		100% { box-shadow: 0 0 4px #FFD98C; color: #FFD98C; }
		0% { color: #CC9D70;	}
		100% { color: #FFD98C; }
	}
/*==========================================================*/

.outlined-button-make-picks:hover,
.outlined-button-player-search:hover,
.outlined-button-race-timer:hover {
		animation: glow 500ms;
		-moz-animation: glow 500ms;
		-webkit-animation: glow 500ms;
		-o-animation: glow 500ms;
		animation-fill-mode: forwards;
}
.outlined-button-race-timer:hover .timertip {
		visibility: visible;
		opacity: 1;
}





.white-text-no-size-change {
	color: #fff;
}
.white-timer-text {
	color: #fff;
	padding: 0;
}
.timer {
	font-size: 1em;
	margin-left: .25em;
	padding: 0;
	color: #CC9D70;
}
/*.results-row a {
	text-decoration: none;
}
.results-row a:link {
	color: #0ECBE6;
}
.results-row a:visited {
	color: #0ECBE6;
}
.results-row a:hover, a:focus {
	color: #0ECBE6;
}
*/
/*.player-row a:hover, a:focus {
	background-color: #98050F;
}
*/
#entrant1-player-picks, 
#entrant2-player-picks, 
#entrant3-player-picks, 
#entrant4-player-picks, 
#entrant5-player-picks, 
#entrant6-player-picks, 
#entrant7-player-picks, 
#entrant8-player-picks, 
#entrant9-player-picks, 
#entrant10-player-picks, 
#entrant11-player-picks, 
#entrant12-player-picks, 
#entrant13-player-picks, 
#entrant14-player-picks, 
#entrant15-player-picks, 
#entrant16-player-picks, 
#entrant17-player-picks, 
#entrant18-player-picks, 
#entrant19-player-picks, 
#entrant20-player-picks, 
#entrant21-player-picks, 
#entrant22-player-picks, 
#entrant23-player-picks, 
#entrant24-player-picks, 
#entrant25-player-picks {
	display: none;
}
#player-picks, 
	#player2-picks,
	#player3-picks,
	#player4-picks,
	#player5-picks,
	#player6-picks,
	#player7-picks,
	#player8-picks,
	#player9-picks,
	#player0-picks,
	#player1-picks,
	#player2-picks,
	#player3-picks,
	#player4-picks,
	#player5-picks,
	#player6-picks,
	#player7-picks,
	#player8-picks,
	#player9-picks {
	display: none;
}
#other-standings {
	display: none;
}

.race1-results, 
	.race2-results,
	.race3-results,
	.race4-results,
	.race5-results,
	.race6-results,
	.race7-results {
	display: none;
	margin-left: 0;
	margin-right: -10px;
}



.player-heading-row {
	margin: 0;
	padding: 0;
	display: grid;
	gap: .3125em 1em;
	grid-template-columns: 2.5em minmax(11em, 10fr) minmax(4em, 3fr) minmax(9em, 9fr);
	grid-template-rows: 2em;
}
.standings .player-row {
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	display: grid;
	gap: .3125em 1em;
	grid-template-columns: 2.5em minmax(11em, 10fr) minmax(4em, 3fr) minmax(9em, 9fr);
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
	line-height: 1.25;
	cursor: pointer;
}
.standings .player-row:hover {
	border: 1px solid #CC9D70;
	border-radius: 4px;
	background: rgba(0,0,0, 1);
}
.picks-row {
	margin: 0;
	padding: 0 10px .125em 0;
	display: grid;
	gap: .3125em 1em;
	align-items: center;
	grid-template-columns: 2.5em minmax(2.7em, 1fr) 8.25em;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
	line-height: 1.25;
	cursor: pointer;
}
.picks-row:hover {
	border: 1px solid #CC9D70;
	border-radius: 4px;
	background: rgba(0,0,0, 1);
}

.picks-row .race-number {
	font-size: .75em;
	padding-top: .375em;
	padding-bottom: .375em;
	color: white;
	text-transform: uppercase;
	text-align: right;
	justify-self: end;
}
.picks-row .horse-pick,
	.race1-pick,
	.race2-pick,
	.race3-pick,
	.race4-pick,
	.race5-pick,
	.race6-pick,
	.race7-pick
	 {
	font-size: 1em;
	font-weight: 400;
	padding-top: .375em;
	padding-bottom: .375em;
	color: #1b95b2;
	justify-self: start;
	cursor: pointer;
}
.picks-row .horse-pick:hover,
	.race1-pick:hover,
	.race2-pick:hover,
	.race3-pick:hover,
	.race4-pick:hover,
	.race5-pick:hover,
	.race6-pick:hover,
	.race7-pick:hover
	 {
	color: #0ECBE6;
}
.picks-row .win-place-price {
	font-size: 1em;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding-top: .375em;
	padding-bottom: .375em;
	color: #FFFFFF;
}
.picks-row .win-price {
	font-size: .875em;
	padding-top: .375em;
	padding-bottom: .375em;
	color: #FFFFFF;
	justify-self: end;
}
.picks-row .place-price {
	font-size: .875em;
	padding-top: .375em;
	padding-bottom: .375em;
	padding-left: .375em;
	color: #FFFFFF;
	justify-self: end;
}
.picks-row .total-price {
	font-size: .875em;
	padding-top: .375em;
	padding-bottom: .375em;
	padding-left: .375em;
	color: #FFFFFF;
	justify-self: end;
}

.standings-header {
	font-size: 1.5em;
	padding-top: .375em;
	padding-bottom: .375em;
	color: #fff; /*#00B979;*/
	font-weight: 400;
	text-align: center;
	cursor: pointer;
}
.contest-title-row .more-standings {
	margin-bottom: .5em;
}
.make-picks-container .contest-details .contest-button-container {
	/*font-size: 1.25em;*/
	color: #fff; /*#00B979;*/
	font-weight: 400;
	text-align: left;
	cursor: pointer;
}
.make-picks-container .contest-button-container .contest-title-text { 
		padding-bottom: 10px;
	}
.make-picks-container .more-standings .contest-title-text {
	/*font-size: 1.25em;*/
	color: #fff; /*#00B979;*/
	font-weight: 400;
	text-align: left;
	cursor: pointer;
	padding-bottom: 10px;
}
.make-picks-container .contest-button-container .more-standings {
	flex: 1 auto 100%;
	margin-bottom: 0;
}	

/*.leaderboard-header {
	font-size: 1.5rem;
	padding-top: .375em;
	padding-bottom: .375em;
	color: #00B979;
	opacity: .9;
	font-weight: 400;
	text-align: center;
}
*/
.main-homepage .leaderboard-header {
	font-size: 1.125em;
	padding-top: .75em;
	padding-bottom: .5em;
	color: #fff; /*#00B979;*/
	font-weight: 600;
	text-align: center;
}


.race-results-wrapper {
	margin-left: 0;
  margin-right: 0;
  padding-right: 4px;
  max-width: 26.5em;
}



.race-results {
	background-image: linear-gradient(230deg, #0c171c 41% 57%, #1b95b2 180%);
	/*background: transparent linear-gradient(237deg, #0D1E24 0%, #1B95B2 100%) 0% 0% no-repeat padding-box;*/
	box-shadow: 2px 2px 2px #CC9D70;
	border-radius: 8px;
	border-top: 1px solid #CC9D70;
}


.results-row-header {
	margin: 0;
	padding: 0 11px 0 0;
	display: grid;
	gap: .3125em 1em;
	align-items: center;
	grid-template-columns: 2.5em minmax(2.7em, 1fr) 8.25em;
	grid-template-rows: minmax(2em, auto);
}
.results-row {
	margin: 0;
	padding: 0 11px .625em 0;
	display: grid;
	gap: .3125em 1em;
	align-items: center;
	grid-template-columns: 2.5em minmax(2.7em, 1fr) 8.25em;
	grid-template-rows: 1.25em;
}
.results-row-scratches {
	margin: 0;
	margin-bottom: .625em;
	padding: 0 11px .625em 0;
	display: grid;
	gap: .3125em 1em;
	align-items: top;
	grid-template-columns: 2.5em 3.7em 1fr;
	grid-template-rows: minmax(auto auto);
}
.results-row .results-horse-number {
	font-size: .75em;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: white;
	text-transform: uppercase;
	text-align: right;
	justify-self: end;
}
.results-row .horse-placed-first,
	.horse-placed-second,
	.horse-placed-third {
	font-size: 1em;
	font-weight: 400;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: #1b95b2;
	justify-self: start;
}
.horse-placed-first a {
	color: #1b95b2;
}
.horse-placed-first a:link,
.horse-placed-first a:visited
 {
	color: #1b95b2;
}
.horse-placed-first a:hover,
.horse-placed-first a:focus
 {
	color: #0ECBE6;
}
.horse-placed-first a {
	color: #1b95b2;
}
.horse-placed-first a {
	color: #1b95b2;
}
.results-row .results-wps-price {
	font-size: .875em;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: #FFFFFF;
}
.results-wps-price .win-price {
	font-size: .875em;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: #fff;
	justify-self: end;
}
.results-wps-price .place-price {
	font-size: .875em;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	padding-left: .375em;
	color: #fff;
	justify-self: end;
}
.results-wps-price .show-price {
	font-size: .875em;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	padding-left: .375em;
	color: #fff;
	justify-self: end;
}
.results-row-header .results-race-number {
	font-size: .75em;
	font-weight: 600;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: #CC9D70;
	text-transform: uppercase;
	text-align: right;
	justify-self: end;
}
.results-row-header .results-header {
	font-size: .75em;
	font-weight: 600;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: #CC9D70;
	justify-self: start;
}
.results-row-header .results-wps-price {
	font-size: .875em;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: #113D47;
}
.results-wps-price .header-win-price {
	font-size: .875em;
	font-weight: 600;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	color: #CC9D70;
	justify-self: end;
}
.results-wps-price .header-place-price {
	font-size: .875em;
	font-weight: 600;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	padding-left: .375em;
	color: #CC9D70;
	justify-self: end;
}
.results-wps-price .header-show-price {
	font-size: .875em;
	font-weight: 600;
/*	padding-top: .375em;
	padding-bottom: .375em;
*/	padding-left: .375em;
	color: #CC9D70;
	justify-self: end;
}
.results-row-scratches .scratches-word {
	font-size: .875em;
	line-height: 1.25;
	color: #fff;
}
.results-row-scratches .scratches {
	font-size: .875em;
	line-height: 1.25;
	color: #fff;
}
.gradient-rule {
	width: 100%;
	height: 2px;
	margin-bottom: .875em;
	background: transparent linear-gradient(90deg, 
		rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 20% 80%, rgba(255, 255, 255, 0));
}
.standings .gradient-rule {
	width: 100%;
	height: 2px;
	margin-top: .25em;
	margin-bottom: .875em;
	background: transparent linear-gradient(90deg, 
		rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 20% 80%, rgba(255, 255, 255, 0));
}

.main-homepage .gradient-rule {
	width: 91%;
	height: 2px;
	margin-left: 4.5%;
	margin-right: 4.5%;
	margin-bottom: .75em;
	background: transparent linear-gradient(90deg, 
		rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 10% 90%, rgba(255, 255, 255, 0));
}
.standings .rank {
	padding-top: .375em;
	padding-bottom: .375em;
	color: rgba(255, 217, 140, 0.7);
	justify-self: end;
}
.standings .player
	 {
	padding-top: .375em;
	padding-bottom: .375em;
	color: #CC9D70;
	justify-self: start;
}
.standings .player:hover {
	color: #FFD98C;
}

.standings .total,
.standings .player-total,
.standings .player2-total,
.standings .player3-total,
.standings .player4-total,
.standings .player5-total,
.standings .player6-total,
.standings .player7-total {
	padding-top: .375em;
	padding-bottom: .375em;
	color: #FFD98C;
	justify-self: end;
	cursor: pointer;
}
	.standings .total a {
		color: #FFD98C;
	}
	.standings .total a:link {
		color: #FFD98C;
	}
	.standings .total a:visited {
		color: #FFD98C;
	}
	.standings .total a:hover, 
	.standings .total a:focus {
		color: #FFD98C;
	}

.standings .player-row .recent-pick {
	padding-top: .375em;
	padding-bottom: .375em;
	padding-left: 8px;
	color: #1b95b2;
	justify-self: start;
	cursor: pointer;
}
.standings .player-row .recent-pick:hover {
	color: #0ECBE6;
}


.standings .rank-heading {
	display: flex;
	align-items: flex-start;
	align-self: center;
	padding-top: .375em;
	padding-bottom: .375em;
	color: #FFFFFF;
	opacity: .8;
	justify-self: end;
	text-transform: uppercase;
	font-weight: 600;
	font-size: .875em;
}
.standings .player-heading {
	display: flex;
	align-items: flex-start;
	align-self: center;
	padding-top: .55em;
	padding-bottom: .375em;
	color: #FFFFFF;
	justify-self: start;
	font-weight: 600;
	font-size: .875em;
}
.standings .darken-text {
	opacity: .8;
}
.standings .ital-text {
	opacity: .8;
	font-style: italic;
}
.standings .total-heading {
	display: flex;
	align-items: flex-start;
	align-self: center;
	padding-top: .375em;
	padding-bottom: .375em;
	color: #FFFFFF;
	opacity: .8;
	justify-self: end;
	text-transform: uppercase;
	font-weight: 600;
	font-size: .875em;
}
.standings .recent-pick-heading {
	display: flex;
	align-items: flex-start;
	align-self: center;
	padding-top: .375em;
	padding-bottom: .375em;
	padding-left: 8px;
	color: #FFFFFF;
	opacity: .8;
	justify-self: start;
	text-transform: uppercase;
	font-weight: 600;
	font-size: .875em;
}

.heading-ital {
	font-family: "Myriad Pro", sans-serif;
	font-weight: 600;
	font-style: italic;
	font-size: 22px;
	color: #fff;
}

.heading-ital-dark {
	font-family: "Myriad Pro", sans-serif;
	font-weight: 600;
	font-style: italic;
	font-size: 22px;
	color: #000;
}

img + .heading-ital {
	padding-top: 20px;
}

.money-color {
	color: #ffffff;
	opacity: .8;
}
.race-date {
	font-size: .875em;
	color: #ffffff;
	opacity: .5;
}



.saddle-cloth-1,
.saddle-cloth-2,
.saddle-cloth-3,
.saddle-cloth-4,
.saddle-cloth-5,
.saddle-cloth-6,
.saddle-cloth-7,
.saddle-cloth-8,
.saddle-cloth-9,
.saddle-cloth-10,
.saddle-cloth-11,
.saddle-cloth-12,
.saddle-cloth-13,
.saddle-cloth-14,
.saddle-cloth-15,
.saddle-cloth-16,
.saddle-cloth-17,
.saddle-cloth-18,
.saddle-cloth-19,
.saddle-cloth-20,
.saddle-cloth-21,
.saddle-cloth-22,
.saddle-cloth-23,
.saddle-cloth-24,
.saddle-cloth-25,
.saddle-cloth-x {
	width: 1.625em;
	line-height: 1.5em;
	text-align: center;
	vertical-align: middle;
		/*padding-top: .05rem;*/
		border-radius: 2px;
		border: 1px solid rgba(0,0,0, 0)
		/*padding-bottom: .25em;*/
}

.saddle-cloth-1 {
	background: #E20100 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #E20100;
}
.saddle-cloth-2 {
	background: #FFF 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #000;
}
.saddle-cloth-3 {
	background: #1230FF 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #1230FF;
}
.saddle-cloth-4 {
	background: #FEFF00 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #000;
}
.saddle-cloth-5 {
	background: #02B308 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #02B308;
}
.saddle-cloth-6 {
	background: #000000 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #fff;
}
.saddle-cloth-7 {
	background: #FE8208 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #FE8208;
}
.saddle-cloth-8 {
	background: #FE34AC 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #FE34AC;
}
.saddle-cloth-9 {
	background: #40E0D0 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #40E0D0;
}
.saddle-cloth-10 {
	background: #800080 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #800080;
}
.saddle-cloth-11 {
	background: #C0C0C0 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #C0C0C0;
}
.saddle-cloth-12 {
	background: #32CD31 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #32CD31;
}
.saddle-cloth-13 {
	background: #8A2CE0 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #8A2CE0;
}
.saddle-cloth-14 {
	background: #808000 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #808000;
}
.saddle-cloth-15 {
	background: #C9C2B7 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #C9C2B7;
}
.saddle-cloth-16 {
	background: #8588C4 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #8588C4;
}
.saddle-cloth-17 {
	background: #151B54 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #151B54;
}
.saddle-cloth-18 {
	background: #4F9159 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #4F9159;
}
.saddle-cloth-19 {
	background: #C2DFFF 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #C2DFFF;
}
.saddle-cloth-20 {
	background: #E5297D 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #E5297D;
}
.saddle-cloth-21 {
	background: #B5B2D9 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #B5B2D9;
}
.saddle-cloth-22 {
	background: #003769 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #003769;
}
.saddle-cloth-23 {
	background: #BED7CE 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #BED7CE;
}
.saddle-cloth-24 {
	background: #34460C 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #34460C;
}
.saddle-cloth-25 {
	background: #003769 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #003769;
}
.saddle-cloth-x {
	background: #C2DFFF 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #C2DFFF;
}
.saddle-cloth-1,
.saddle-cloth-2,
.saddle-cloth-3,
.saddle-cloth-4,
.saddle-cloth-5,
.saddle-cloth-6,
.saddle-cloth-7,
.saddle-cloth-8,
.saddle-cloth-9,
.saddle-cloth-10,
.saddle-cloth-11,
.saddle-cloth-12,
.saddle-cloth-13,
.saddle-cloth-14,
.saddle-cloth-15,
.saddle-cloth-16,
.saddle-cloth-17,
.saddle-cloth-18,
.saddle-cloth-19,
.saddle-cloth-20,
.saddle-cloth-21,
.saddle-cloth-22,
.saddle-cloth-23,
.saddle-cloth-24,
.saddle-cloth-25,
.saddle-cloth-x {
	width: 22px;
	line-height: 20px;
	text-align: center;
	vertical-align: middle;
		/*padding-top: .05rem;*/
		border-radius: 2px;
		border: 1px solid rgba(0,0,0, 0)
		/*padding-bottom: .25em;*/
}

.odds-race-container .saddle-cloth-1 {
	background: #E20100 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #E20100;
}
.odds-race-container .saddle-cloth-2 {
	background: #FFF 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #000;
}
.odds-race-container .saddle-cloth-3 {
	background: #1230FF 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #1230FF;
}
.odds-race-container .saddle-cloth-4 {
	background: #FEFF00 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #000;
}
.odds-race-container .saddle-cloth-5 {
	background: #02B308 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #02B308;
}
.odds-race-container .saddle-cloth-6 {
	background: #000000 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #fff;
}
.odds-race-container .saddle-cloth-7 {
	background: #FE8208 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #FE8208;
}
.odds-race-container .saddle-cloth-8 {
	background: #FE34AC 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #FE34AC;
}
.odds-race-container .saddle-cloth-9 {
	background: #40E0D0 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #40E0D0;
}
.odds-race-container .saddle-cloth-10 {
	background: #800080 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #800080;
}
.odds-race-container .saddle-cloth-11 {
	background: #C0C0C0 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #C0C0C0;
}
.odds-race-container .saddle-cloth-12 {
	background: #32CD31 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #32CD31;
}
.odds-race-container .saddle-cloth-13 {
	background: #8A2CE0 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #8A2CE0;
}
.odds-race-container .saddle-cloth-14 {
	background: #808000 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #808000;
}
.odds-race-container .saddle-cloth-15 {
	background: #C9C2B7 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #C9C2B7;
}
.odds-race-container .saddle-cloth-16 {
	background: #8588C4 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #8588C4;
}
.odds-race-container .saddle-cloth-17 {
	background: #151B54 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #151B54;
}
.odds-race-container .saddle-cloth-18 {
	background: #4F9159 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #4F9159;
}
.odds-race-container .saddle-cloth-19 {
	background: #C2DFFF 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #C2DFFF;
}
.odds-race-container .saddle-cloth-20 {
	background: #E5297D 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #E5297D;
}
.odds-race-container .saddle-cloth-21 {
	background: #B5B2D9 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #B5B2D9;
}
.odds-race-container .saddle-cloth-22 {
	background: #003769 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #003769;
}
.odds-race-container .saddle-cloth-23 {
	background: #BED7CE 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #BED7CE;
}
.odds-race-container .saddle-cloth-24 {
	background: #34460C 0% 0% no-repeat padding-box;
	color: #fff;
	border: 1px solid #34460C;
}
.odds-race-container .saddle-cloth-25 {
	background: #003769 0% 0% no-repeat padding-box;
	color: #000;
	border: 1px solid #003769;
}
/*==================================MAKE PICKS SPECIFIC CODE========*/



	.weight {
		color: #8a8a8a;
	}

	.make-picks-block-wrapper {
		background-image: linear-gradient(230deg, #0c171c 41% 57%, #1b95b2 180%); 
	}
	.make-picks-contest-title-row {
		margin: 0;
		padding: 10px;
	}

/*================================== STYLES FOR CAROUSEL RACES */
/*	.upcoming-races {
		display: flex;
		justify-content: start;		
	}
*/

	.rec-item-wrapper {
		display: flex;
		justify-content: start !important;
		width: 100% !important;
		/*padding: 0 1rem !important;*/
	}
	.rec-carousel {
		padding: 0 1rem;
	}
	.rec-pagination {
		display: none !important;
	}
	.rec-arrow-left {
		width: .5em !important;
		/*height: 0 !important;*/
		min-width: 0 !important;
		text-align: left !important;
		padding: 0 .9em 0 0 !important;
		box-shadow: none !important;
		background-color: rgba(0,0,0, 0) !important;
		color: #0ECBE6 !important;
	}
	.rec-arrow-right {
		width: .5em !important;
		/*height: 0 !important;*/
		min-width: 28px !important;
		text-align: right !important;
		padding: 0 !important;
		box-shadow: none !important;
		background-color: rgba(0,0,0, 0) !important;
		color: #0ECBE6 !important;
		/*padding-right: 2rem !important;*/
	}
	.rec-slider-container {
		margin: 0 !important;
	}
	.make-picks-event-row ol {
		margin: 0 .75rem;
		padding: 0 0;
		display: flex;
		justify-content: start;
	}
	.event-card {
		width: 4em !important;
		font-size: 1em;
		line-height: 1.25;
		background-color: rgba(0,0,0, 1);
		color: #fff;
		margin: 10px 5px;
		padding: 5px 5px 5px 5px;
		border-radius: 3px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		border: 1px solid #CC9D70;
		border-radius: 4px;
		cursor: pointer;
	}
	.event-card-active {
		width: 4em !important;
		font-size: 1em;
		line-height: 1.25;
		background-color: rgba(14,203,230, .2);
		color: #fff;
		margin: 10px 5px;
		padding: 5px 5px 5px 5px;
		border-radius: 3px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		border: 1px solid #CC9D70;
		border-radius: 4px;
	}
	.event-card:hover,
	.event-card:focus {
		animation: eventglow 300ms;
		-moz-animation: eventglow 300ms;
		-webkit-animation: eventglow 300ms;
		-o-animation: eventglow 300ms;
		animation-fill-mode: forwards;
	}
/*	.event-races {
		display: flex;
		justify-content: space-between; 
		align-items: center;
	}
*/
	/*=================EVENT CARD ANIMATION=========================================*/
@keyframes eventglow {
		0% { box-shadow: 0 0 0px #FFD98C; color: #CC9D70; }
		100% { box-shadow: 0 0 4px #FFD98C; color: #FFD98C; }
}
/*==========================================================*/
	.event-date {
		/*padding: 0 10px;*/
		margin-left: auto;
		margin-right: auto;/*width: 100%;*/
		text-align: center;
		color: #858585;
		flex-basis: 100%;
	}
	.event-track-and-race {
		/*padding: 0 10px;*/
		margin-left: auto;
		margin-right: auto;/*width: 100%;*/
		text-align: center;
		color: #0ECBE6;
		flex-basis: 100%;
	}
	.make-picks-event-row .left-more-races-arrow {
		display: block;
	}
	.make-picks-event-row .right-more-races-arrow {
		transform: rotate(180deg);
	}
	.make-picks-race-detail-row {
			margin: 0;
			padding: 0 2rem 1em;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
		}
	.make-picks-next-race-row {
			margin: 0;
			padding: 0 1rem 1rem;
			display: flex;
			justify-content: space-between;	 
	}
	.previous-race, 
	.next-race {
			color: #0ECBE6;
			cursor: pointer;
	}
	.make-picks-next-race-row .previous-race img {
		padding-right: .375em;
		vertical-align: bottom;
	}
	.make-picks-next-race-row .next-race img {
		padding-left: .375em;
		vertical-align: bottom;
	}
	/*Hides the 52px margin accounting for mobile nav at bottom */
	.margin-for-make-picks-mobile { 
		display: none;
	}

	.make-picks-race-detail-row .event-number,
	.make-picks-race-detail-row .event-details,
	.make-picks-race-detail-row .event-time,
	.make-picks-race-detail-row .picks-deadline,
	.make-picks-race-detail-row .post-time {
			font-size: 1.125em;
			padding: .375em .5em;
		}
	.make-picks-race-detail-row .event-number {
			color: #00B979;
	}
	.make-picks-race-detail-row .event-details {
			color: #0ECBE6;
	}
	.make-picks-race-detail-row .gray-text {
			color: #666;
	}
	.make-picks-race-detail-row .post-time {
			color: #fff;
	}


	.boxed-event {
		font-size: 1rem;
		border-radius: 2px;
		text-align: center;
		vertical-align: middle;
		padding-top: .25em;
		padding-right: .25em;
		padding-bottom: .25em;
		padding-left: .25em;
		background: rgba(152,5,15, .5);
		color: #fff;
	}
	.timer-color {
		font-size: 1.125em;
		color: #CC9D70;
	}

/*---------------------------------------------------ODDS */


	.odds-race-container {
		max-width: 825px;
		padding: 5px 1rem 5px;
	}
	.odds-sub-container {
		max-width: 350px;
		margin: 0 auto;
	}
	.odds-main-wrapper .make-picks-event-row ol {
		margin: 0 .75rem;
		padding: 0 0;
		display: flex;
		justify-content: start;
		border-bottom: 1px solid rgba(255,255,255, .5) ;
	}
	.odds-heading-row {
		padding: 0 10px;
		display: grid;
		gap: .3125em 1em;
		grid-template-columns: minmax(1.75em, 2em) minmax(2em, 2.5em) minmax(8em, 1fr) minmax(2em, 3em);
		/*grid-template-columns: minmax(2.75em, 10fr) 3em minmax(8em, 7fr) 4em minmax(1px, 8fr);*/
		grid-template-rows: 2.625em;
		border-bottom: 1px solid rgba(255,255,255, .5) ;
	}
	.odds-heading-row .program-number,
	.odds-heading-row .number-of-picks,
	.odds-heading-row .horse-name,
	.odds-heading-row .sok-odds {
			font-size: .875em;
			font-weight: 600;
			text-transform: uppercase;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: end;
			justify-self: center;
			color: rgba(255,255,255, .7);
		}
	.odds-heading-row .horse-name {
			justify-self: start;
		}
	.odds-heading-row .program-number {
			justify-self: end;
			padding-right: .375em;
		}
	.odds-heading-row .sok-odds {
			justify-self: end;
			text-align: right;
		}
	.total-picks-for-race {
		padding-top: .5em;
		font-size: 1.5em;
		text-align: center;
		color: #CC9D70;
	}
	.white-text {
		color: #fff;
	}
	.odds-race-entrant-row {
			margin: 0 0;
			padding: 0 10px;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(1.75em, 2em) minmax(2em, 2.5em) minmax(8em, 1fr) minmax(2em, 3em);
			/*grid-template-columns: minmax(2.75em, 10fr) 3em minmax(8em, 7fr) 4em minmax(1px, 8fr);*/
			grid-template-rows: 2.25em;
			border: 1px solid rgba(0,0,0, 0);
			line-height: 1.25;
			cursor: pointer;
	} 

/* For 1024+
		.odds-race-entrant-row {	
			margin: 0 222px;
			padding: 0 10px;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(2.75em, 10fr) 3em minmax(8em, 7fr) 4em minmax(1px, 8fr);
			grid-template-rows: 2.25em;
			border: 1px solid rgba(0,0,0, 0);
			line-height: 1.25;
			cursor: pointer;
	} 
*/	.odds-race-entrant-row a:link,
	.odds-race-entrant-row a:visited, 
	.odds-race-entrant-row a:link,
	.odds-race-entrant-row a:visited {
		border: 1px solid rgba(0,0,0, 0);
	}
	.odds-race-entrant-row:hover {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		background: rgba(0,0,0, 1);
	}
	.odds-race-entrant-row:on(click) {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		background: rgba(0,0,0, 1);
	}
	.odds-race-container ol li:first-child {
				margin-top: .25em;
	}
	.odds-race-container ol li:last-child {
				margin-bottom: .5em;
	}
	.odds-race-entrant-row .program-number,
	.odds-race-entrant-row .number-of-picks,
	.odds-race-entrant-row .horse-name,
	.odds-race-entrant-row .sok-odds {
			font-size: 1em;
			color: #fff;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: end;
		}
	.odds-race-entrant-row .number-of-picks {
		justify-self: center;
	}

	.odds-race-entrant-row .horse-name {
			font-size: 1em;
			color: #1b95b2;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
		}
	.odds-race-entrant-row .horse-name:hover {
			color: #0ECBE6;
		}
	.odds-race-entrant-row .sok-odds {
		/*padding-right: .75em;*/
		color: rgba(255,255,255, .7);
	}
	.odds-player-picks-heading {
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		display: grid;
		gap: .3125em 1em;
		grid-template-columns: minmax(2.75em, 1fr) minmax(9em, 3fr) minmax(4em, 2fr);
		grid-template-rows: 1.75em;
		border: 1px solid rgba(0,0,0, 0);
	}
	.odds-player-picks-heading .rank-heading {
		padding-top: .375em;
		color: rgba(255, 255, 255, 0.7);
		justify-self: end;
	}
	.odds-player-picks-heading .player-heading {
		padding-top: .375em;
		color: rgba(255, 255, 255, 0.7);
		justify-self: start;
	}
	.odds-player-picks-heading .total-heading {
		padding-top: .375em;
		color: rgba(255, 255, 255, 0.7);
		justify-self: end;
	}
	.players-who-picked {
		margin-left: 15px;
		padding: 4px 0 10px 0;
		color: rgba(255,255,255, .7);
		border-bottom: 1px solid rgba(255,255,255, .7);
		text-align: center;
	}
	.horse-name-color {
		color: #1b95b2;
/*		opacity: 1;*/
	}
	.odds-race-container .player-row {
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		display: grid;
		gap: .3125em 1em;
		grid-template-columns: minmax(2.75em, 1fr) minmax(9em, 3fr) minmax(4em, 2fr);
		grid-template-rows: 2.25em;
		border: 1px solid rgba(0,0,0, 0);
	}
/*	.odds-race-container .player-row:hover {
			border: 1px solid #CC9D70;
			border-radius: 4px;
			background: rgba(0,0,0, 1);
		}
*/	.odds-race-container .player-row .rank {
		padding-top: .375em;
		padding-bottom: .375em;
		color: rgba(255, 217, 140, 0.7);
		justify-self: end;
	}
	.odds-race-container .player-row .player {
		padding-top: .375em;
		padding-bottom: .375em;
		color: #CC9D70;
		justify-self: start;
	}
	.odds-race-container .player-row .total {
		padding-top: .375em;
		padding-bottom: .375em;
		color: #FFD98C;
		justify-self: end;
	}
	.odds-player-picks {
		margin: 0 auto;
		max-width: 350px;
		border-radius: 0 0 8px 8px;
		padding: 10px 15px 0 0;
		background: #000;
		-moz-box-shadow:    inset 0 0 6px #fff;
		-webkit-box-shadow: inset 0 0 6px #fff;
		box-shadow:         inset 0 0 6px #fff;
	}
/*-------------------------------------------------------END ODDS*/
	.make-picks-race-container {
		max-width: 793px;
		padding: 5px 1rem 5px;
	}
	.make-picks-heading-row {
		padding: 0 10px;
		display: grid;
		gap: .3125em 1em;
		grid-template-columns: 2.75em 2em minmax(7em, 1fr) 3em 7em minmax(6em, 8em) 4em 4em;
		grid-template-rows: 3.625em;
		border-top: 1px solid rgba(255,255,255, .5) ;
		border-bottom: 1px solid rgba(255,255,255, .5) ;
	}
	.make-picks-heading-row .program-number,
	.make-picks-heading-row .post-position,
	.make-picks-heading-row .horse-name,
	.make-picks-heading-row .ml-odds,
	.make-picks-heading-row .jockey-name,
	.make-picks-heading-row .trainer-name,
	.make-picks-heading-row .main-pick,
	.make-picks-heading-row .alt-pick {
			font-size: 1em;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: end;
			color: rgba(255,255,255, 1);
		}
	.make-picks-heading-row .post-position,
	.make-picks-heading-row .main-pick, 
	.make-picks-heading-row .alt-pick {
			justify-self: center;
			text-align: center;
			color: rgba(255,255,255, .7);
		}
	.make-picks-heading-row .horse-name,
	.make-picks-heading-row .jockey-name,
	.make-picks-heading-row .trainer-name {
			justify-self: start;
		}
	.make-picks-heading-row .ml-odds {
			justify-self: center;
			text-align: center;
			color: rgba(255,255,255, .7);
		}
	.make-picks-heading-row .program-number {
		padding-right: .4em;
	}

	.make-picks-race-entrant-row {
			margin: 0;
			padding: 0 10px;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: 2.75em 2em minmax(7em, 1fr) 3em 7em minmax(6em, 8em) 4em 4em;
			grid-template-rows: 3.625em;
			border: 1px solid rgba(0,0,0, 0);
	}
	
	.make-picks-race-entrant-row:hover {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		background: rgba(0,0,0, 1);
	}

	.make-picks-race-container ol li:first-child {
				margin-top: .25em;
	}
	.make-picks-race-container ol li:last-child {
				margin-bottom: .75em;
	}

	.make-picks-race-entrant-row .post-position,
	.make-picks-race-entrant-row .horse-name,
	.make-picks-race-entrant-row .ml-odds,
	.make-picks-race-entrant-row .jockey-name,
	.make-picks-race-entrant-row .trainer-name,
	.make-picks-race-entrant-row .main-pick,
	.make-picks-race-entrant-row .alt-pick {
			font-size: 1em;
			color: #fff;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: end;
		}
				
	.make-picks-race-entrant-row .post-position,
	.make-picks-race-entrant-row .main-pick,
	.make-picks-race-entrant-row .alt-pick {
			justify-self: center;
		}

	.make-picks-race-entrant-row .horse-name,
	.make-picks-race-entrant-row .jockey-name,
	.make-picks-race-entrant-row .trainer-name {
			justify-self: start;
		}
	.make-picks-race-entrant-row .ml-odds {
		padding-right: .75em;
		color: rgba(255,255,255, .7);
	}
	.make-picks-race-entrant-row .program-number {
			font-size: 1em;
			align-self: center;
			justify-self: end;
	}
	.make-picks-race-entrant-row .live-odds,
	.make-picks-heading-row .live-odds {
		display: none;
	}

	.make-picks-race-entrant-row.scratched {
		opacity: 0.3;
	}

	.race-selection-container {
		max-width: 825px;
		padding: 5px 1rem 20px;
	}
	/*.race-selection-heading-row {
		padding: 0 10px;
		display: grid;
		gap: .3125em 1em;
		grid-template-columns: 4.75em 2em minmax(7em, 1fr) 3em 7em minmax(6em, 8em) 4em 4em;
		grid-template-rows: 3.625em;
		border-top: 1px solid rgba(255,255,255, .5) ;
		border-bottom: 1px solid rgba(255,255,255, .5) ;
	}
*//*	.make-picks-heading-row .program-number,
	.make-picks-heading-row .post-position,
	.make-picks-heading-row .horse-name,
	.make-picks-heading-row .ml-odds,
	.make-picks-heading-row .jockey-name,
	.make-picks-heading-row .trainer-name,
	.make-picks-heading-row .main-pick,
	.make-picks-heading-row .alt-pick {
			font-size: 1em;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: end;
			color: rgba(255,255,255, 1);
		}
	.make-picks-heading-row .post-position,
	.make-picks-heading-row .main-pick, 
	.make-picks-heading-row .alt-pick {
			justify-self: center;
			text-align: center;
			color: rgba(255,255,255, .7);
		}
	.make-picks-heading-row .horse-name,
	.make-picks-heading-row .jockey-name,
	.make-picks-heading-row .trainer-name {
			justify-self: start;
		}
	.make-picks-heading-row .ml-odds {
			justify-self: center;
			text-align: center;
			color: rgba(255,255,255, .7);
		}
	.make-picks-heading-row .program-number {
		padding-right: .4em;
	}*/

	.race-selection-row-header,
	.race-event-number-header,
	.race-event-details-header,
	.race-event-time-header,
	.race-picks-deadline-header {
		display: none;
	}

	.race-selection-row {
			margin: 0 -5px 0 -3px;
			padding: 0 5px 0 2.5em;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(3.5em, 6em) minmax(5.5em, 8em) minmax(10em, 12em) 11em minmax(1.5em, 1fr);
			grid-template-rows: 2.5em;
			border: 1px solid rgba(0,0,0, 0);
			cursor: pointer;
	}
	
	.race-selection-row a:link,
	.race-selection-row a:visited {
		border: 1px solid rgba(0,0,0, 0);
	}

	.race-selection-row:hover {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		background: rgba(0,0,0, 1);
	}

	.race-selection-right-carat-blue {
		width: 9px;
		vertical-align: top;
	}

	.race-event-number {
			font-size: 1em;
			color: #00B979;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;		
	}
	.race-event-details {
			font-size: 1em;
			color: #0ECBE6;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
	}
	.race-event-details .gray-text {
			color: #666;
	}
	.race-event-time {
			font-size: 1em;
			color: #fff;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
	}
	.race-event-time .gray-text {
			color: #666;
	}
	.race-picks-deadline {
			font-size: 1.125em;
			color: #CC9D70;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;		
	}
	.picks-deadline-text {
		font-size: .875em;
		color: #fff;		
	}
	.race-carat-right {
			padding-top: .375em;
			padding-bottom: .375em;
			padding-right: .325em;
			align-self: center;
			justify-self: end;
			color: #0ECBE6;
		}



/*==================================FOR CUSTOM RADIO BUTTONS*/


	.radio-button-container {
			display: block;
			position: relative;
/*			padding-left: 30px;
			margin-bottom: 10px;
*/			cursor: pointer;
			/*font-size: 20px;*/
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
	 }

	/* Hide the browser's default radio button */
	.radio-button-container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.radio-checkmark {
		position: absolute;
		top: -12px;
		left: -12px;
		height: 24px;
		width: 24px;
		background-color: #000;
		border-radius: 50%;
		border: 1px solid #CC9D70;
	}
	/* On mouse-over, add a gray background color */
	.radio-button-container:hover input ~ .radio-checkmark {
		background-color: #98050F;
	}
	
	/* When the radio button is selected, add a blue background */
	.radio-button-container input:checked ~ .radio-checkmark {
		 background-color: #98050F;
	}

	/* Create the indicator (the checkmark - hidden when not checked) */
	.radio-checkmark:after {
		content: '\2713';
		font-size: 1.5em;
		position: absolute;
		display: none;
	}
	/* Show the indicator (the checkmark) when checked */
	.radio-button-container input:checked ~ .radio-checkmark:after {
		display: block;
	}
	/* Style the indicator (the checkmark) */
	.radio-button-container .radio-checkmark:after {
		top: -3px;
		left: 3px;
	}


/*==================================END CUSTOM RADIO BUTTONS*/

	.make-picks-advertisement {
		width: 100%;
		max-width: 287px;
		height: 254px;
		margin-top: 0;
		margin-bottom: .75em;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
		border-radius: 8px;
		background-color: #0C171C;
		font-weight: 600;
		font-size: 1.25em;
	}
	.make-picks-advertisement a {
		cursor: auto;
	}
	.make-picks-advertisement img {
		max-width: 287px;
	}
	.make-picks-advertisement-empty {
		width: 100%;
		max-width: 287px;
		height: 254px;
		margin-top: 0;
		margin-bottom: .75em;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		border-radius: 8px;
		color: #fff;  /*This is the gold: CC9D70*/
		background-color: #0C171C;
		font-weight: 600;
		font-size: 1.25em;
		line-height: 1.5;
	}
	
	.your-picks-column {
		margin-top: 0;
		margin-bottom: .75em;
		border: 1px solid #00B979; /*GREEN #00B979*/
		border-radius: 8px;
		color: #fff;
		background-color: #0C171C;
	}
	.your-picks1-button {
		display: flex;
		justify-content: space-between;
		margin: 0;
	}
	.your-picks-button-container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 0;
		margin-bottom: 0;
		padding-top: .375em;
		padding-bottom: .375em;
		padding-left: 1rem;
		padding-right: 1rem;
		border-radius: 8px;
		color: #fff;  /*This is the gold: CC9D70*/
		background-color: #0C171C;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.5em;
		text-align: center;
		cursor: pointer;
	}
	.your-picks-button-container:hover {
			animation: button-toggle-animation 300ms;
			-moz-animation: button-toggle-animation 300ms;
			-webkit-animation: button-toggle-animation 300ms;
			-o-animation: button-toggle-animation 300ms;
			animation-fill-mode: forwards;
		border: none;
	}
	.your-picks-heading {

	}

	.your-picks-row-header {
		margin: 0 10px;
		padding: 0 -10px;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: minmax(1em, 5fr) minmax(5.5em, 3fr) minmax(10em, 10fr);
		grid-template-rows: 1.75em;
		border-bottom: 1px solid rgba(255,255,255, .5);
	}
	ol .your-picks-row {
		margin: 0 10px;
		padding: 0 -10px;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: minmax(1em, 5fr) minmax(5.5em, 3fr) minmax(10em, 10fr);
		grid-template-rows: 2.25em;
		border: 1px solid rgba(0,0,0, 0);
		border-radius: 4px;
	}
	.your-picks-event-header {
			font-size: .8125em;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: end;
	}
	.your-picks-race-header {
			font-size: .8125em;
			color: #fff;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
	}
	.your-picks-horse-header {
			font-size: .8125em;
			color: #fff;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
	}



					
	ol .player-picks {
				/*border: 1px solid #fff;*/
	}
						
		ol .your-picks-row:hover {
		border: 1px solid #CC9D70;
		border-radius: 4px;
		background: rgba(0,0,0, 1);
	}

	.your-picks-reveal ol li:first-child {
				margin-top: .25em;
	}
	.your-picks-reveal ol li:last-child {
				margin-bottom: .75em;
	}
	ol .your-picks-race-number {
				font-size: .8875em;
				padding-top: .375em;
				padding-bottom: .375em;
				color: #00B979;
				align-self: center;
				justify-self: end;
	}
	ol .your-picks-race-track-date {
				font-size: .8875em;
				padding-top: .375em;
				padding-bottom: .375em;
				color: #1b95b2;
				align-self: center;
				justify-self: start;
	}
	ol .your-picks-horse-name {
				font-size: 1em;
				padding-top: .375em;
				padding-bottom: .375em;
				color: #98050F;
				align-self: center;
				justify-self: start;
	}

	.light-black-text {
		color: rgba(255,255,255, .7);
	}
	.red-text {
		color: #CB0906;
	}
	.live-race-pick {
		color: #98050F;
		text-decoration: none;
	}
	.live-race-pick a {
		color: #98050F;
	}
	.live-race-pick a:link {
		color: #98050F;
	}
	.live-race-pick a:visited {
		color: #98050F;
	}
	.live-race-pick a:hover, a:focus {
		color: #98050F;
	}


/*-------------------------Benefactors Page Styles -----------------*/
.benefactors {
	font-size: 1em;
	color: #fff;
	line-height: 1.75;
}
/*@media only screen and (min-width: 1025px) {*/
.benefactors ul {
    -moz-column-count: 4;
    -moz-column-gap: 48px;
    -webkit-column-count: 4;
    -webkit-column-gap: 48px;
    column-count: 4;
    column-gap: 48px;
    list-style: none;
    margin: 0 0 2em;
    text-indent:-.75em;
}
.benefactors-intro-text .about-logo-container {
	width: 156px;
	margin: 40px auto;
}
.benefactors-intro-text p {
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
	font-size: 1em;
	color: #fff;
	opacity: 1;
	font-weight: 400;
	line-height: 1.75;
	padding-bottom: 1em;
}
.benefactors-intro-text h2 {
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.25em;
	margin-top: .5em;
	margin-bottom: .5em;
	color: #fff;
	opacity: 1;
	font-weight: 600;
	line-height: 1.15;
}

/*-------------------------Text Heavy Page Styles -----------------*/

.general-text-wrapper  {
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
}
.general-text h2 {
	font-size: 1.25em;
	margin-top: .5em;
	margin-bottom: .5em;
	color: #fff;
	font-weight: 400;
	line-height: 1.15;
}
.general-text p {
	font-size: 1em;
	color: #b8babc;
	font-weight: 400;
	line-height: 1.75;
	padding-bottom: 1em;
}
.general-image-container {

}
.general-text .about-logo-container {
	width: 156px;
	margin: 40px auto;
}
.centered-image {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin: 20px auto;
}
.podcast-image {
	padding: .5em .25em;
}

/*-------------------------Events Page Styles -----------------*/

img[alt="Pick Your Prize Contest Graphic"] {
  width: 500px;
}
img[alt="Image Width 250px"] {
  width: 250px;
}

/*===========================================================================
===========================================================================
MEDIA QUERIES
===========================================================================
===========================================================================
*/


@media screen and (min-width: 321px) {
	html {
		font-size: calc(16px + 2 * ((100vw - 320px) / 680));
	}
}

@media (max-width: 420px) {
		.results-row {
		grid-template-rows: 2.25em;
	}
	.odds-race-container {
		padding: 5px 5px 5px;
	}
	.odds-player-picks {
		margin: 0 10px;
		max-width: 350px;
		border-radius: 0 0 8px 8px;
		padding: 10px 15px 0 0;
		background: #000;
		-moz-box-shadow:    inset 0 0 6px #fff;
		-webkit-box-shadow: inset 0 0 6px #fff;
		box-shadow:         inset 0 0 6px #fff;
	}
	.odds-heading-row {
		margin: 0 5px;
		padding: 0 5px;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: minmax(1.75em, 2em) minmax(2em, 2.5em) minmax(8em, 1fr) minmax(2em, 3em);
		grid-template-rows: 2.625em;
		/*border-top: 1px solid rgba(255,255,255, .5) ;*/
		border-bottom: 1px solid rgba(255,255,255, .5) ;
	}
	.odds-heading-row .sok-odds {
			justify-self: end;
			text-align: right;
		}
	.odds-race-entrant-row {
			margin: 0 5px;
			padding: 0 5px;
			display: grid;
			gap: .3125em .75em;
			grid-template-columns: minmax(1.75em, 2em) minmax(2em, 2.5em) minmax(8em, 1fr) minmax(2em, 3em);
			grid-template-rows: 2.25em;
			border: 1px solid rgba(0,0,0, 0);
			line-height: 1.25;
			cursor: pointer;
	} 
	.odds-race-entrant-row .sok-odds {
		padding-right: 0;
	}
	.odds-player-picks-heading {
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: minmax(2em, 3em) minmax(8em, 1fr) 3em;
		grid-template-rows: 1.75em;
		border: 1px solid rgba(0,0,0, 0);
	}
	.odds-race-container .player-row {
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: minmax(2em, 3em) minmax(8em, 1fr) 3em;
		grid-template-rows: 2.25em;
		border: 1px solid rgba(0,0,0, 0);
	}
}

@media (max-width: 700px) {
/*	#pps {
		display: none;
	}
*/

	.sok-logo {
	display: none;
	/*width: 56px;*/
	}
	.sok-horse-logo {
	width: 40px;
	}
	.sok-logo-no-stars {
		width: 70px;
	}
nav {
		position: absolute;
		top: 53px;
		width: 100vw;
		left: -100vw;
		height: 100%;
		background: black;
		opacity: 1;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		z-index: 100;
		transition: all .2s ease;
	}
	
	#nav-checkbox:checked ~ nav {
		left: 0;
	}
	#nav-checkbox:checked ~ .close-button img {
		display: inline;
	}
	#nav-checkbox:checked ~ .menu-button {
		display: none;
	}
	.menu-button {
			position: absolute;
			top: 12px;
			right: 20px;
			font-size: 1.25em;
			color: #fff;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
	}
	.close-button img {
		display: none;
		width: 30px;
		cursor: pointer;
	}

	nav ul {
		margin: 0 0 0 20px;
		padding: 0;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: space-between; 
		align-items: left; 
		font-weight: 600;
		letter-spacing: 0.03em;
	}
	nav li a,
	nav li .nav-signup {
		font-size: 1.25em;
		display: block;
		font-weight: 400;
		letter-spacing: 0.03em;
		color: #fff;
		text-decoration: none;
		padding: 15px 0;
	}
	.outer-flex-container {
			display: flex;
			justify-content: space-between;
	}
	.outer-wrapper {
			background-color: #000;
			padding: 0 20px;
	}
	.content-wrapper {
			margin: auto;
			max-width: 1110px;
	}
	/*-------------------------------------------------Footer Styles*/
	.sok-tagline {
		/*display: none;*/
	}
	.footer-container h3 {
		/*display: none;*/
	}
	.contact-word {
		display: inline-block;
		color: #fff;
	}
	.footer-grid {
		grid-template-columns: 1fr;
	}
	.sok-address {
		display: none;
	}
	/*---------------------------------------------END-Footer Styles*/
	
	main {
		margin: 0;
		max-width: 825px;
		display: grid;
		gap: 0 30px;
		/*padding-top: 20px;*/
		grid-template-columns: 3fr;
	}
	.banner-wrapper {
		margin: 0 auto .5em;
		justify-content: center; 
	}
	.sok-animation {
		display: none;
	}
	.drf-banner {
		margin: 0;
	}
	.drf-banner img {
		max-width: 100%;
	}
	.main-homepage .leaderboards {
		max-width: 825px;
		margin: 0 auto 20px;
		padding: 0;
		display: grid;
		gap: 1em;
		justify-items: center;
		grid-template-columns: 1fr 1fr;
	}

	.standings .outlined-red-buttons-row {
		display: flex;
		/*flex-wrap: wrap;*/
		justify-content: space-between;
		margin: .25em 0 1.25em 0;
		/*align-content: top;*/
		align-items: stretch;
	}
	
.outlined-button-make-picks {		/*Minor spacing issue on mobile*/
			margin: 3px .5em 0 0;
	}

	.outlined-button-player-search {
		display: none;
	}
	.outlined-button-race-timer {
		margin: 3px 0 0 .5em;
		align-items: baseline;
}
	.white-timer-text {
		color: #fff;
		padding: 0;
	}
	.timer {
		margin-left: .25em;
		padding: 0;
		color: #CC9D70;
		/*align-self: stretch;*/
	}

	.race-selection-row-header {
			display: block;
			margin: 0 0 10px 0;
			padding: 0 0 0 2.5em;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(3.5em, 6.5em) minmax(5.5em, 8em) minmax(5em, 8em) 5em minmax(1.5em, 1fr);
			grid-template-rows: 3.25em;
			/*border: 1px solid rgba(0,0,0, 0);*/
			border-top: 1px solid rgba(255,255,255, .5);
			border-bottom: 1px solid rgba(255,255,255, .5);
	}
	
	.race-event-number-header {
			display: block;
			font-size: .875em;
			color: rgba(255,255,255, .7);
			text-transform: uppercase;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;		
	}
	.race-event-details-header {
			display: block;
			font-size: .875em;
			color: rgba(255,255,255, .7);
			text-transform: uppercase;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
	}
	.race-event-time-header {
			display: block;
			font-size: .875em;
			color: rgba(255,255,255, .7);
			text-transform: uppercase;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
	}
	.race-picks-deadline-header {
			display: block;
			font-size: .875em;
			color: rgba(255,255,255, .7);
			text-transform: uppercase;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;		
	}

	.race-selection-row {
			margin: 0 0 0 0;
			padding: 0 0 0 2.5em;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(3.5em, 6.5em) minmax(5.5em, 8em) minmax(5em, 8em) 5em minmax(1.5em, 1fr);
			grid-template-rows: 2.5em;
			border: 1px solid rgba(0,0,0, 0);
			cursor: pointer;
	}

	.race-event-time {
			font-size: 1em;
			color: #fff;
			padding-top: .375em;
			padding-bottom: .375em;
			align-self: center;
			justify-self: start;
	}
	.race-event-time .post-time-text {
			display: none;
	}

	.picks-deadline-text {
		display: none;
	}

	.past-performances-wrapper .race-selection-row-header {
		display: none;
	}

	.past-performances-wrapper .race-selection-row {
			margin: 0 0 0 0;
			padding: 0 0 0 2.5em;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(3.5em, 6.5em) minmax(5.5em, 8em) minmax(11em, 1fr) 1.5em;
			grid-template-rows: 2.5em;
			border: 1px solid rgba(0,0,0, 0);
			cursor: pointer;
	}


		.make-picks-heading-row {
				padding: 0 0;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(8em, 1fr) minmax(7em, 1fr) 3em 3em; 
				grid-template-rows: 1.5em 1.5em;
			}
		.make-picks-race-entrant-row {
				margin: 0 -.5rem;
				padding: 0 .5rem;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(8em, 1fr) minmax(7em, 1fr) 3em 3em;
				grid-template-rows: 2em 2em;
		}
		.make-picks-heading-row .post-position,	
		.make-picks-heading-row .trainer-name { /*CHANGE*/
				display: none;
			}
		.make-picks-heading-row .main-pick, 
		.make-picks-heading-row .jockey-name,/*CHANGE*/
		.make-picks-heading-row .alt-pick {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: center;
				align-self: center;
				text-align: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-heading-row .horse-name,
		.make-picks-heading-row .trainer-name { /*CHANGE*/
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: end;
			}
		.make-picks-heading-row .jockey-name {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-heading-row .ml-odds {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: start;
				grid-row-start: 2;
				grid-row-end: 3;
				grid-column-start: 2;
				grid-column-end: 3;
			}
		.make-picks-heading-row .program-number {
				padding-top: 0;
				padding-bottom: 0;
				padding-right: .4em;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
		}


		.make-picks-race-entrant-row .post-position,
		.make-picks-race-entrant-row .trainer-name { /*CHANGE*/
				display: none;
			}
					
		.make-picks-race-entrant-row .post-position,
		.make-picks-race-entrant-row .main-pick,
		.make-picks-race-entrant-row .alt-pick {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}

		.make-picks-race-entrant-row .horse-name,
		.make-picks-race-entrant-row .trainer-name {/*CHANGE*/
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: end;
			}
		.make-picks-race-entrant-row .jockey-name {/*CHANGE*/
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-race-entrant-row .ml-odds {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: start;
				grid-row-start: 2;
				grid-row-end: 3;
				grid-column-start: 2;
				grid-column-end: 3;
		}
		.make-picks-race-entrant-row .program-number {
				font-size: 1em;
				align-self: center;
				justify-self: end;
				grid-row-start: 1;
				grid-row-end: 3;
		}
		.make-picks-race-entrant-row .live-odds,
		.make-picks-heading-row .live-odds {
			display: none;
		}



	.make-picks-race-detail-row .post-time {
			display: none;
		}
/*--------------FOR THE MAKE PICKS NEXT RACE/PREVIOUS RACE TO STICK TO BOTTOM*/
	.make-picks-next-race-row {
			position: fixed;
			background: rgba(0,0,0, .9);
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;
			padding: 1rem 1rem 1rem;
			border-top: 1px solid rgba(255,255,255, .5);
			display: flex;
			justify-content: space-between;	 
	}
	.margin-for-make-picks-mobile {
		display: block;
		height: 52px;
	}
	.previous-race, 
	.next-race {
			color: #0ECBE6;
	}
	.make-picks-next-race-row .previous-race img {
		padding-right: .375em;
		vertical-align: bottom;
	}
	.make-picks-next-race-row .next-race img {
		padding-left: .375em;
		vertical-align: bottom;
	}
/*--------------FOR MAKE PICKS and STANDINGS - Put Your Picks on top of the grid
																							 REPEATS IN MEDIA 701-886*/

	.main-make-picks,
	.main-standings {
		grid-row-start: 1;
		grid-row-end: 2;
	}
	.your-picks-column {
		grid-row-start: 2;
		grid-row-end: 3;
	}
	.main-standings .contests-title-row {
		grid-row-start: 3;
		grid-row-end: 4;
	}
	.your-picks-reveal {
		/*display: none;*/
	}
	.make-picks-advertisement-empty,
	.standings-advertisement,
	.standings-google-adsense,
	.standings-google-adsense-701-886 {
		display: none;
	}
	.standings-google-adsense-700-or-less {
		display: flex;
		justify-content: center;
		margin: 6px auto;
		/*border: 1px solid #fff;*/
	}

	.benefactors ul {
	    -moz-column-count: 2;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 2;
	    -webkit-column-gap: 20px;
	    column-count: 2;
	    column-gap: 20px;
	    list-style: none;
	    margin: 1em 0;
	}
	.benefactors-intro-text p {
		padding: 0 0 .5em;
	}
/*--------------FOR BANNER ON HOMEPAGE TO ONLY SHOW BANNER1*/
	.banner-container ul {
		display: flex;
		justify-content: center;
	}
	.banner-container li {
		padding-right: 0; 
	}
	.banner2 {
		display: none;
	}
	.banner3 {
		display: none;
	}

}

@media (max-width: 365px) {
	.main-homepage .leaderboards {
		max-width: 825px;
		margin: 0 auto 20px;
		padding: 0;
		display: grid;
		gap: 1em;
		justify-items: center;
		grid-template-columns: 1fr;
	}
		.standings .player-row .recent-pick,
	.standings .player-row .player-recent-pick,
	.standings .player-row .player2-recent-pick,
	.standings .player-row .player3-recent-pick,
	.standings .player-row .player4-recent-pick,
	.standings .player-row .player5-recent-pick,
	.standings .player-row .player6-recent-pick,
	.standings .player-row .player7-recent-pick,
	.standings .player-row .player8-recent-pick,
	.standings .player-row .player9-recent-pick,
	.standings .player-row .player0-recent-pick,
	.standings .player-row .player1-recent-pick,
	.standings .player-row .player2-recent-pick,
	.standings .player-row .player4-recent-pick,
	.standings .player-row .player5-recent-pick,
	.standings .player-row .player6-recent-pick,
	.standings .player-row .player7-recent-pick,
	.standings .player-row .player8-recent-pick,
	.standings .player-row .player9-recent-pick	{
			display: none; 
		}
		.standings .recent-pick-heading {
			display: none;
		}
	.standings {
		padding: 5px 10px 20px 10px;
		margin: 0 -10px;
	}
	.standings .player-heading-row {
		margin: 0;
		padding: 0 10px 0 0;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: 2em minmax(3em, 10fr) minmax(3em, 3fr);
		grid-template-rows: 3em;
	}
	.standings .player-row {
		margin: 0;
    padding: .25em 10px .5em 10px;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: 2em minmax(4em, 3fr) minmax(3em, 3fr);
		grid-template-rows: 2.25em;
		border: 1px solid rgba(0,0,0, 0);
		line-height: 1.25;
	}
	.standings .picks-row {
	margin: 0 -10px 0 -10px;
	padding: .25em 10px .5em 10px;
	display: grid;
	gap: .3125em .75em;
	align-items: center;
	grid-template-columns: 2em minmax(1.7em, 1fr) 8.25em;
	grid-template-rows: 2.25em;
	border: 1px solid rgba(0,0,0, 0);
	line-height: 1.25;
	}
	.results-row {
		grid-template-rows: 2.25em;
	}
	.benefactors ul {
	    -moz-column-count: 1;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 1;
	    -webkit-column-gap: 20px;
	    column-count: 1;
	    column-gap: 20px;
	    list-style: none;
	    margin: 1em 0;
	}
	.benefactors-intro-text p {
		padding: 0 1em .5em;
	}

}
@media (min-width: 366px) and (max-width: 587px) {
	.main-homepage .leaderboards {
		max-width: 825px;
		margin: 0 auto 20px;
		padding: 0;
		display: grid;
		gap: 1em;
		justify-items: center;
		grid-template-columns: 1fr;
	}

		.standings .player-row .recent-pick,
	.standings .player-row .player-recent-pick,
	.standings .player-row .player2-recent-pick,
	.standings .player-row .player3-recent-pick,
	.standings .player-row .player4-recent-pick,
	.standings .player-row .player5-recent-pick,
	.standings .player-row .player6-recent-pick,
	.standings .player-row .player7-recent-pick,
	.standings .player-row .player8-recent-pick,
	.standings .player-row .player9-recent-pick,
	.standings .player-row .player0-recent-pick,
	.standings .player-row .player1-recent-pick,
	.standings .player-row .player2-recent-pick,
	.standings .player-row .player4-recent-pick,
	.standings .player-row .player5-recent-pick,
	.standings .player-row .player6-recent-pick,
	.standings .player-row .player7-recent-pick,
	.standings .player-row .player8-recent-pick,
	.standings .player-row .player9-recent-pick	{
			display: none;
		}
		.standings .recent-pick-heading {
			display: none;
		}

	.standings .player-heading-row {
		margin: 0;
		padding: 0 10px 0 0;
		display: grid;
		gap: .3125em 1em;
		grid-template-columns: 2.5em minmax(11em, 10fr) minmax(3em, 3fr);
		grid-template-rows: 3em;
	}
	.standings .player-row {
		margin: 0;
		padding: 0 10px 0 0;
		display: grid;
		gap: .3125em 1em;
		grid-template-columns: 2.5em minmax(11em, 10fr) minmax(3em, 3fr);
		grid-template-rows: 2.25em;
		border: 1px solid rgba(0,0,0, 0);
		line-height: 1.25;
	}
	.picks-row {
		padding: .25em 10px .5em 0;
	}


}



@media (max-width: 492px) {	/*QUERY TO DISPLAY WITHOUT JOCKEY NAME AND REMOVE RACE PICKS DEADLINE*/
	
		.make-picks-heading-row {
				padding: 0 0;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(7em, 1fr) 3em 3em; 
				grid-template-rows: 1.5em 1.5em;
			}
		.make-picks-race-entrant-row {
				margin: 0 -.5rem;
				padding: 0 .5rem;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(7em, 1fr) 3em 3em;
				grid-template-rows: 2em 2em;
		}
		.make-picks-heading-row .jockey-name { /*CHANGE*/
				display: none;
			}
		.make-picks-race-entrant-row .jockey-name { /*CHANGE*/
				display: none;
			}
	.race-selection-row-header {
			display: block;
			margin: 0 0 10px 0;
			padding: 0;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(3em, 5em) minmax(4em, 6em) minmax(4em, 6em) minmax(1.5em, 1fr);
			grid-template-rows: 3.25em;
			/*border: 1px solid rgba(0,0,0, 0);*/
			border-top: 1px solid rgba(255,255,255, .5) ;
			border-bottom: 1px solid rgba(255,255,255, .5) ;
	}

	.race-picks-deadline-header {
			display: none;
	}

	.race-selection-row {
			margin: 0 -10px;
			padding: 0 10px;
			display: grid;
			gap: .3125em 1em;
			grid-template-columns: minmax(3em, 5em) minmax(4em, 6em) minmax(4em, 6em) minmax(1.5em, 1fr);
			grid-template-rows: 2.5em;
			border: 1px solid rgba(0,0,0, 0);
			cursor: pointer;
	}
	.race-picks-deadline {
		display: none;
	}
	.past-performances-wrapper .race-selection-row {
			margin: 0 0 0 0;
			padding: 0 0 0 0.5em;
			display: grid;
			gap: .3125em .5em;
			grid-template-columns: minmax(3.5em, 1fr) minmax(3em, 1fr) minmax(10em, 6fr);
			grid-template-rows: 3.5em;
			border: 1px solid rgba(0,0,0, 0);
			cursor: pointer;
	}
			/*This is to swap out DRF mobile PPs image from Formulator and Classic PPs, since default at this size is to show them in that format on DRF*/
	.mobile-pps {
		display: block;
	}
	.regular-pps {
		display: none;
	}

}
@media (min-width: 588px) and (max-width: 700px) {
	.outer-flex-container {
			display: flex;
			justify-content: space-between;
	}
	.outer-wrapper {
			background-color: #000;
			padding: 0 20px;
	}
	.content-wrapper {
			margin: auto;
			max-width: 1110px;
	}
	main {
		margin: 0;
		max-width: 825px;
		display: grid;
		gap: 0 30px;
		grid-template-columns: 3fr;
	}

	.standings {
	font-size: 1em;
	}
	.picks-row {
		margin: 0;
		padding: 0 0 .125em 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns: 2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(9em, 10.5fr);
		grid-template-rows: 2.25em;
		max-width: 26.5em;
	}
	.results-row-header {
		margin: 0;
		padding: 0 11px 0 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns:	2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(9em, 10.5fr);
		grid-template-rows: minmax(2em, auto);
	}
	.results-row {
		margin: 0;
		padding: 0 11px .625em 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns: 2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(4.75em, 10.5fr);
		grid-template-rows: 1.25em;
	}

	.race1-results,
	.race2-results,
	.race3-results,
	.race4-results,
	.race5-results,
	.race6-results,
	.race7-results {
		margin-left: 0;
		margin-right: -10px;
		max-width: 22.5em;
	}
	.registration-account-column,
	.contests-column,
	.make-picks-column {
		padding: 0 0 0 0;
	}
	
}

/*--------------------------FOR STANDINGS - Put Your Picks on top of the grid */

@media (min-width: 701px) and (max-width: 886px) { 

	.standings-main-wrapper main {
		margin: 0 auto 0 auto;
		max-width: 100%;
		display: grid;
		gap: 0 .75em;
		grid-template-columns: 3fr;
	}
	.standings .your-picks-column {
		grid-row-start: 1;
		grid-row-end: 2;
	}
	.main-standings .contests-title-row {
		grid-row-start: 3;
		grid-row-end: 4;
	}
	.main-standings {
		grid-row-start: 2;
		grid-row-end: 3;
	}
	.standings-main-wrapper .your-picks-reveal {
		display: none;
	}
	.standings-advertisement,
	.standings-google-adsense {
		display: none;
	}
	.standings-google-adsense-701-886 {
		display: flex;
		justify-content: center;
		margin: 6px auto;
		/*border: 1px solid #fff;*/
	}
	.standings-google-adsense-700-or-less {
		display: none;
	}

	.main-homepage .leaderboards {
		max-width: 100%;
		margin: 0 auto 20px;
		padding: 0;
		display: grid;
		gap: 1em;
		justify-items: center;
		grid-template-columns: 1fr 1fr;
	}
	.main-homepage .leaderboard-column {
		max-width: 100%;
		background-image: linear-gradient(230deg, #0c171c 41% 57%, #1b95b2 180%);		 
		box-shadow: 2px 2px 2px #CC9D70;
		border-radius: 8px;
	}
	.main-homepage .player-row {
		margin: 0 10px 0 10px;
		padding: 0 10px 0 10px;
		display: grid;
		gap: .3125em .75em;
		grid-template-columns: 1em minmax(5em, 1fr) 2.5em;
		grid-template-rows: 2.25em;
		border: 1px solid rgba(0,0,0, 0);
		line-height: 1.25;
	}

}


/*-----------------------THIS QUERY STRICTLY FOR MAKE PICKS to lose jockey column + SOK animation*/

@media (min-width: 701px) and (max-width: 802px) { 
		.sok-animation {
		font-size: 1em;
		}

		.make-picks-heading-row {
				padding: 0 0;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(7em, 1fr) 3em 3em; 
				grid-template-rows: 1.5em 1.5em;
			}
		.make-picks-race-entrant-row {
				margin: 0 -.5rem;
				padding: 0 .5rem;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(7em, 1fr) 3em 3em;
				grid-template-rows: 2em 2em;
		}
		.make-picks-heading-row .jockey-name { 
				display: none;
			}
		.make-picks-race-entrant-row .jockey-name { 
				display: none;
			}
		.make-picks-heading-row .post-position,	
		.make-picks-heading-row .trainer-name { 
				display: none;
			}
		.make-picks-heading-row .main-pick, 
		.make-picks-heading-row .jockey-name,
		.make-picks-heading-row .alt-pick {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: center;
				align-self: center;
				text-align: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-heading-row .horse-name,
		.make-picks-heading-row .trainer-name { 
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: end;
			}
		.make-picks-heading-row .jockey-name {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-heading-row .ml-odds {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: start;
				grid-row-start: 2;
				grid-row-end: 3;
				grid-column-start: 2;
				grid-column-end: 3;
			}
		.make-picks-heading-row .program-number {
				padding-top: 0;
				padding-bottom: 0;
				padding-right: .4em;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
		}


		.make-picks-race-entrant-row .post-position,
		.make-picks-race-entrant-row .trainer-name { /*CHANGE*/
				display: none;
			}
					
		.make-picks-race-entrant-row .post-position,
		.make-picks-race-entrant-row .main-pick,
		.make-picks-race-entrant-row .alt-pick {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}

		.make-picks-race-entrant-row .horse-name,
		.make-picks-race-entrant-row .trainer-name {/*CHANGE*/
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: end;
			}
		.make-picks-race-entrant-row .jockey-name {/*CHANGE*/
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-race-entrant-row .ml-odds {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: start;
				grid-row-start: 2;
				grid-row-end: 3;
				grid-column-start: 2;
				grid-column-end: 3;
		}
		.make-picks-race-entrant-row .program-number {
				font-size: 1em;
				align-self: center;
				justify-self: end;
				grid-row-start: 1;
				grid-row-end: 3;
		}
		.make-picks-race-entrant-row .live-odds,
		.make-picks-heading-row .live-odds {
			display: none;
		}


}
/*-----------------------THIS QUERY STRICTLY FOR MAKE PICKS to continue changing + SOK animation*/

@media (min-width: 803px) and (max-width: 1023px) { 
		.sok-animation {
			font-size: 1.25em;
		}
		.make-picks-heading-row {
				padding: 0 0;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(8em, 1fr) minmax(7em, 9em) 3em 3em; 
				grid-template-rows: 1.5em 1.5em;
			}
		.make-picks-race-entrant-row {
				margin: 0 -.5rem;
				padding: 0 .5rem;
				display: grid;
				gap: 0 1em;
				grid-template-columns: 1.625rem minmax(8em, 1fr) minmax(7em, 9em) 3em 3em;
				grid-template-rows: 2em 2em;
		}
		.make-picks-heading-row .post-position,	
		.make-picks-heading-row .trainer-name { 
				display: none;
			}
		.make-picks-heading-row .main-pick, 
		.make-picks-heading-row .jockey-name,
		.make-picks-heading-row .alt-pick {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: center;
				align-self: center;
				text-align: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-heading-row .horse-name,
		.make-picks-heading-row .trainer-name { 
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: end;
			}
		.make-picks-heading-row .jockey-name {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-heading-row .ml-odds {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: start;
				grid-row-start: 2;
				grid-row-end: 3;
				grid-column-start: 2;
				grid-column-end: 3;
			}
		.make-picks-heading-row .program-number {
				padding-top: 0;
				padding-bottom: 0;
				padding-right: .4em;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
		}


		.make-picks-race-entrant-row .post-position,
		.make-picks-race-entrant-row .trainer-name { /*CHANGE*/
				display: none;
			}
					
		.make-picks-race-entrant-row .post-position,
		.make-picks-race-entrant-row .main-pick,
		.make-picks-race-entrant-row .alt-pick {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}

		.make-picks-race-entrant-row .horse-name,
		.make-picks-race-entrant-row .trainer-name {/*CHANGE*/
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: end;
			}
		.make-picks-race-entrant-row .jockey-name {/*CHANGE*/
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: center;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		.make-picks-race-entrant-row .ml-odds {
				padding-top: 0;
				padding-bottom: 0;
				justify-self: start;
				align-self: start;
				grid-row-start: 2;
				grid-row-end: 3;
				grid-column-start: 2;
				grid-column-end: 3;
		}
		.make-picks-race-entrant-row .program-number {
				font-size: 1em;
				align-self: center;
				justify-self: end;
				grid-row-start: 1;
				grid-row-end: 3;
		}
		.make-picks-race-entrant-row .live-odds,
		.make-picks-heading-row .live-odds {
			display: none;
		}


}


	
@media (min-width: 701px) and (max-width: 900px) {	 /*USE THIS MEDIA QUERY ONLY FOR NAV*/
	 /* [This is a display text hack for the nav!!!]*/
	.just-free-pps {
		display: inline;
	}
	.button-pps {
		display: none;
	}

}

@media (min-width: 887px) and (max-width: 1023px) {	/* This is just for Your Picks to show
																												to the right of standings*/
	.standings-main-wrapper main {
		margin: 0 auto 0 auto;
		max-width: 100%;
		display: grid;
		gap: 0 .75em;
		grid-template-columns: 3fr 287px;
	}
}


@media (min-width: 701px) and (max-width: 1023px) {
	.nav-outer-wrapper {
		background-color: #000;
		padding: 0 20px;
	}
	.navbar {
		margin: auto;
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.logo-container {		/*CHANGE ORDER*/
		/*flex-shrink: 2;*/
		width: 156px;
	}
	.sok-logo {		
		width: 100%;
	}
	.sok-logo-no-stars {
		display: none;
	}

	#nav-checkbox {
		display: none;
	}
	nav {
		width: 100%; 
	}
	nav ul {
		margin: 0 0 0 2.5em; 
		padding: 0;
		display: flex;
		justify-content: space-between; 
		align-items: center; 
		font-weight: 600;
		letter-spacing: 0.03em;
	}
	nav li:last-of-type {
		padding-right: 1.25em; 
	}

	.menu-button, .close-button img {
		display: none;
	}
	.outer-flex-container {
			display: flex;
			justify-content: space-between;
	}
	.outer-wrapper {
			background-color: #000;
			padding: 0 20px;
	}
	.content-wrapper {
			margin: auto;
			max-width: 1110px;
	}

	.make-picks-main-wrapper main {
		margin: 0 auto 0 auto;
		max-width: 825px;
		display: grid;
		gap: 0 .75em;
		/*padding-top: 20px;*/
		grid-template-columns: 2fr 287px;
	}
	main {
		margin: 0 auto;
		max-width: 825px;
		display: grid;
		gap: 0 30px;
		grid-template-columns: 3fr;
	}
	.side-nav-buttons {
		grid-row-start: 3;
		grid-row-end: 4;
	}

	.standings {
		font-size: 1em;
	}
	.picks-row {
		margin: 0;
		padding: 0 0 .125em 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns: 2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(9em, 10.5fr);
		grid-template-rows: 2.25em;
		max-width: 26.5em;
	}
	.results-row-header {
		margin: 0;
		padding: 0 11px 0 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns:	2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(9em, 10.5fr);
		grid-template-rows: minmax(2em, auto);
	}
	.results-row {
		margin: 0;
		padding: 0 11px .625em 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns: 2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(4.75em, 10.5fr);
		grid-template-rows: 1.25em;
	}
	.race1-results,
	.race2-results,
	.race3-results,
	.race4-results,
	.race5-results,
	.race6-results,
	.race7-results {
		margin-right: -10px;
		max-width: 22.5em;
	}



	.picks-row .race-number {
		font-size: .75em;
		padding-top: .375em;
		padding-bottom: .375em;
		color: white;
		text-transform: uppercase;
		text-align: right;
		justify-self: end;
	}
/*	.picks-row .horse-pick,
		.race1-pick,
		.race2-pick,
		.race3-pick,
		.race4-pick,
		.race5-pick,
		.race6-pick,
		.race7-pick
		 {
		font-size: 1em;
		font-weight: 400;
		padding-top: .375em;
		padding-bottom: .375em;
		color: #0ECBE6;
		justify-self: start;
		cursor: pointer;
	}
*/	.picks-row .win-place-price {
		font-size: 1em;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		padding-top: .375em;
		padding-bottom: .375em;
		color: #FFFFFF;
	}
	.picks-row .win-price {
		font-size: .875em;
		padding-top: .375em;
		padding-bottom: .375em;
		color: #FFFFFF;
		justify-self: end;
	}
	.picks-row .place-price {
		font-size: .875em;
		padding-top: .375em;
		padding-bottom: .375em;
		padding-left: .375em;
		color: #FFFFFF;
		justify-self: end;
	}
	.picks-row .total-price {
		font-size: .875em;
		padding-top: .375em;
		padding-bottom: .375em;
		padding-left: .375em;
		color: #FFFFFF;
		justify-self: end;
	}



	.contests-column {
		padding: 5px 0 20px 0;
	}
	.contests-container {
		max-width: 825px;
	}
	.contests-container .contest1-reveal, 
	.contests-container .contest2-reveal,
	.contests-container .contest3-reveal,
	.contests-container .contest4-reveal {
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;
	}
/*--------------FOR BANNER ON HOMEPAGE TO ONLY SHOW BANNERS 1 & 2*/
	.banner-container ul {
		display: flex;
		justify-content: space-evenly;
	}
	.banner3 {
		display: none;
	}
	.banner-container li {
		padding-right: 0em;
	}
	.banner-container li:first-of-type {
		padding-right: .5em; 
	}
}
/*===========================================================================
===========================================================================
DESKTOP SIZE
*/

@media (min-width: 1024px) {
	html {
		font-size: 16px;
	}
	.nav-outer-wrapper {
		background-color: #000;
		padding: 0 20px;
	}
	.navbar {
		margin: auto;
		padding-top: 20px;
		padding-bottom: 20px;
		max-width: 1110px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.logo-container {		
		width: 9.75em;
	}
	.sok-logo {		
		width: 100%;
	}
	.sok-logo-no-stars {
		display: none;
	}
	#nav-checkbox {
		display: none;
	}
	nav {
		width: 100%;	
	}
	nav ul {
		margin: 0 0 0 2.5em;
		padding: 0;
		display: flex;
		justify-content: space-between; 
		align-items: center; 
		font-weight: 600;
		letter-spacing: 0.03em;
	}
	nav li .button-pps, .just-free-pps, .nav-register, .nav-signup {
		border: 1px solid #CC9D70;
		border-radius: 8px;
		padding-left: .6em;
		padding-right: .6em;
		padding-top: .4em;
		padding-bottom: .5em;
		color: #fff;
		cursor: pointer;
	}
	nav li .button-pps:hover, 
	nav li .nav-register:hover, 
	nav li .nav-signup:hover, 
	nav li .button-pps:focus,
	nav li .nav-register:focus,
	nav li .nav-signup:focus	{
		animation: glowyellow 500ms;
		-moz-animation: glowyellow 500ms;
		-webkit-animation: glowyellow 500ms;
		-o-animation: glowyellow 500ms;
		animation-fill-mode: forwards;
	}
		nav li:last-of-type {
		padding-right: 1.85em; 
	}

.menu-button, .close-button img {
		display: none;
	}
	.outer-flex-container {
			display: flex;
			justify-content: space-between;
	}
	.outer-wrapper {
			background-color: #000;
			padding: 0 20px;
	}
	.outer-wrapper-white {
			background-color: #fff;
			padding: 0 20px;
	}
	.content-wrapper {
			margin: auto;
			max-width: 1110px;
	}

	


	main {
		margin: 0;
		max-width: 1110px;
		display: grid;
		gap: 0 30px;
		grid-template-columns: 255px 3fr;
	}
	.make-picks-main-wrapper main {
		margin: 0;
		max-width: 1110px;
		display: grid;
		gap: 0 30px;
		/*padding-top: 20px;*/
		grid-template-columns: 3fr 287px;
	}
	.odds-main-wrapper main {
		margin: 0;
		max-width: 1110px;
		display: grid;
		gap: 0 30px;
		/*padding-top: 20px;*/
		grid-template-columns: 255 3fr;
	}
	.standings-main-wrapper main {
		margin: 0;
		max-width: 1110px;
		display: grid;
		gap: 0 30px;
		/*padding-top: 20px;*/
		grid-template-columns: 3fr 287px;
	}
	.picks-row {
		margin: 0;
		padding: 0 0 .125em 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns: 2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(9em, 10.5fr);
		grid-template-rows: 2.25em;
		max-width: 26.5em;
	}
	.results-row-header {
		margin: 0;
		padding: 0 11px 0 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns:	2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(9em, 10.5fr);
		grid-template-rows: minmax(2em, auto);
	}
	.results-row {
		margin: 0;
		padding: 0 11px .625em 0;
		display: grid;
		gap: .3125em 1em;
		align-items: center;
		grid-template-columns: 2.5em minmax(13em, 3fr) minmax(8.25em, 1.5fr) minmax(4.75em, 10.5fr);
		grid-template-rows: 1.25em;
	}
	.race1-results,
	.race2-results,
	.race3-results,
	.race4-results,
	.race5-results,
	.race6-results,
	.race7-results {
		margin-right: -10px;
		max-width: 22.5em;
	}

	.picks-row .race-number {
		font-size: .75em;
		padding-top: .375em;
		padding-bottom: .375em;
		color: white;
		text-transform: uppercase;
		text-align: right;
		justify-self: end;
	}
	.picks-row .win-place-price {
		font-size: 1em;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		padding-top: .375em;
		padding-bottom: .375em;
		color: #FFFFFF;
	}
	.picks-row .win-price {
		font-size: .875em;
		padding-top: .375em;
		padding-bottom: .375em;
		color: #FFFFFF;
		justify-self: end;
	}
	.picks-row .place-price {
		font-size: .875em;
		padding-top: .375em;
		padding-bottom: .375em;
		padding-left: .375em;
		color: #FFFFFF;
		justify-self: end;
	}
	.picks-row .total-price {
		font-size: .875em;
		padding-top: .375em;
		padding-bottom: .375em;
		padding-left: .375em;
		color: #FFFFFF;
		justify-self: end;
	}

	.side-nav-buttons {
		width: 255px;
		display: grid;
		gap: 0;
		align-content: start;
		grid-row-start: 1;
		grid-row-end: 2;
	}
	.make-picks-main-wrapper .side-nav-buttons {
		display: none;
	}
	.main-registration-wrapper 	.side-nav-buttons {
		display: none;
	}
	.main-registration-wrapper main {
		margin: 0 auto;
		max-width: 825px;
		display: grid;
		gap: 0 30px;
		grid-template-columns: 3fr;
	}



	.contests-column {
		padding: 0 0 20px 0;
	}
/*	.contests-container .contest-details .contest-button-container {
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;
	}
*/	.make-picks-container .contest-details .contest-button-container {
		margin-top: 0;
		margin-bottom: 0;
	}

	.make-picks-container .contest-button-container .contest-title-text { 
		padding-bottom: 10px;
	}

	.make-picks-container .contest-button-container .more-standings {
		flex: 1 auto 100%;
		margin-bottom: 0;
	}	

	.contests-container {
		max-width: 825px;
	}
	.contests-container .contest1-reveal, 
	.contests-container .contest2-reveal,
	.contests-container .contest3-reveal,
	.contests-container .contest4-reveal {
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;
	}

}

@media (min-width: 1024px) and (max-width: 1094px) { /*THIS QUERY STRICTLY FOR MAKE PICKS to lose trainer column*/
	.make-picks-heading-row {
			grid-template-columns: 2.75em 2em minmax(7em, 1fr) 3em 7em 4em 4em;
		}
	.make-picks-heading-row .trainer-name,
	.make-picks-race-entrant-row .trainer-name {
			display: none;
		}
	.make-picks-race-entrant-row {
			grid-template-columns: 2.75em 2em minmax(7em, 1fr) 3em 7em 4em 4em;
	}

}

